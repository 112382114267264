import React from "react";
import TextField from "@mui/material/TextField";

const ProfilePage = () => {
  return (
    <>
      <section className="contact-box-section">
        <div className="container-fluid-lg" style={{ background: "#fff" }}>
          <div className="row g-lg-5 g-3">
            <div className="col-lg-12" style={{ padding: "0px 10px" }}>
              <div
                className="right-sidebar-box"
                style={{ padding: "0px", background: "#fff" }}
              >
                <div className="row">
                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput"
                        className="form-label"
                      >
                        Name *
                      </label>
                      <div className="custom-input">
                        <input
                          type="text"
                          className="form-control"
                          id="fullname"
                          placeholder="Enter Name"
                          style={{
                            border: "1px solid #94b1c870",
                            borderRadius: "10px",
                          }}
                        />
                        <i className="fa-solid fa-user" />
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-6 col-lg-12 col-sm-6">
                    <div className="mb-md-4 mb-3 custom-form">
                      <label
                        htmlFor="exampleFormControlInput2"
                        className="form-label"
                      >
                        Email Address *
                      </label>
                      <div className="custom-input">
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          placeholder="Enter Email Address"
                          style={{
                            border: "1px solid #94b1c870",
                            borderRadius: "10px",
                          }}
                        />
                        <i className="fa-solid fa-envelope" />
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="btn btn-animation btn-md fw-bold ms-auto"
                  style={{ borderRadius: "6px" }}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "15px 0px",
              border: "1px solid rgb(148 177 200 / 19%)",
            }}
          ></div>
          <div>
            <div className="profile_header">Delete Account</div>
            <div className="profile_paragraph">
              Deleting your account will remove all your orders, wallet amount
              and any active refferal.{" "}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ProfilePage;
