import React, { useEffect, useState } from "react";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { addtoCart, decrementCart } from "../../../redux/cart/CartSlice";
import axios from "axios";
import { Baseurl } from "../../../config/BaseUrl";
import { MdFormatListBulletedAdd } from "react-icons/md";
import { AiOutlineAppstoreAdd } from "react-icons/ai";
import { IoIosArrowDown } from "react-icons/io";
import ClipLoader from "react-spinners/ClipLoader";

const RecentOrders = () => {
  const { OrganicVegetable, CartItems } = useSelector((state) => state.Cart);
  const { clientOrder } = useSelector((state) => state.Order);
  const dispatch = useDispatch();
  const [toChangeColor, setToChangeColor] = useState(false);
  const [hotProducts, setHotProducts] = useState([]);
  const [protest, setProtest] = useState(false);

  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };
  const bg = {
    overlay: {
      background: "#FFFF00",
    },
  };

  useEffect(() => {
    try {
      if (clientOrder.length > 0) {
        let order_product = [];
        for (let index = 0; index < clientOrder.length; index++) {
          let element = clientOrder[index].OrderProducts;
          order_product = [...order_product, ...element];
        }
        const uniqueProducts = order_product.reduce((acc, current) => {
          if (!acc.find((item) => item.ProductId === current.ProductId)) {
            acc.push(current);
          }
          return acc;
        }, []);

        let prod = uniqueProducts;

        let orderproducts = prod.map((product) => ({
          ...product,
          CartQuantity: 0,
          SellingPrice: product.Price,
        }));

        CartItems.forEach((cartitem) => {
          let itemIndex = orderproducts.findIndex(
            (product) =>
              String(product.ProductId) === String(cartitem.ProductId) &&
              String(product.packsizeId) === String(cartitem.packsizeId)
          );
          if (itemIndex >= 0) {
            orderproducts[itemIndex].CartQuantity = cartitem.Qty;
          }
        });

        setHotProducts(orderproducts);
      }
    } catch (error) {}
  }, [CartItems, OrganicVegetable, clientOrder]);

  const addtocartPress = async (e, hotPro, itemIndex) => {
    try {
      let products = [...hotProducts];

      products[itemIndex].CartQuantity += 1;

      setHotProducts([...products]);
      setProtest(!protest);
    } catch (error) {}

    const formData = hotPro;
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex) => {
    try {
      let products = hotProducts;
      products[itemIndex].CartQuantity -= 1;

      setHotProducts(products);
    } catch (error) {}

    const formData = hotPro;
    dispatch(decrementCart(formData));
  };

  return (
    <>
      {clientOrder.length > 0 ? (
        <>
          <section>
            <div className="container">
              <div className="title">
                <h2
                  className="hotproducttext"
                  style={{
                    textAlign: "center",
                    marginTop:"6px"
                  }}
                >
                  Recent Orders
                </h2>
                {/* <span className="title-leaf">
                  <svg className="icon-width">
                    <use xlinkHref="https://themes.pixelstrap.com/fastkart/assets/svg/leaf.svg#leaf" />
                  </svg>
                </span> */}
              </div>
              <div className="product-border border-row mt-1">
                <div className="slider-6_2 no-arrow">
                  <Swiper
                    // draggable={true}
                    grabCursor={true}
                    navigation={false}
                    pagination={false}
                    mousewheel={false}
                    keyboard={true}
                    modules={[
                      Navigation,
                      Pagination,
                      Mousewheel,
                      Keyboard,
                      // Autoplay,
                    ]}
                    // loop={true}
                    // autoplay={{
                    //   delay: 1500,
                    //   disableOnInteraction: false,
                    //   pauseOnMouseEnter: true,
                    // }}
                    breakpoints={{
                      360: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      460: {
                        slidesPerView: 2.6,
                        spaceBetween: 5,
                      },
                      720: {
                        slidesPerView: 3.5,
                        spaceBetween: 20,
                      },
                      1080: {
                        slidesPerView: 5.8,
                        spaceBetween: 7,
                      },
                    }}
                    className="mySwipersub"
                  >
                    <>
                      {hotProducts &&
                        hotProducts.map((product, itemIndex) => (
                          <SwiperSlide key={product._id}>
                            <div className=" px-0 mt-2">
                              <div className="product-box wow fadeIn">
                                <>
                                  {/* <Link to={`/product-info/${product.Url}`}> */}
                                  <div
                                    className="product-image"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <a>
                                      <img
                                        src={product.ImgUrl}
                                        className="img-fluid blur-up lazyload offertext"
                                        alt
                                      />

                                      {product.Mrp === product.SellingPrice ? (
                                        <> </>
                                      ) : (
                                        <>
                                          <span id="lorel">
                                            {product.Discount}% off
                                          </span>
                                          <span id="lorel1">
                                            {product.Discount}% off
                                          </span>
                                        </>
                                      )}
                                    </a>
                                  </div>
                                  {/* </Link> */}

                                  <div className="product-detail paddtext">
                                    <h6 className="sold weight text-content fw-normal">
                                      {product.Brand}
                                    </h6>
                                    <a>
                                      <h6
                                        className="name name-2 h-100"
                                        style={{
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {product.ItemName}
                                      </h6>
                                    </a>

                                    <h6
                                      className="sold"
                                      style={{
                                        color: "#000",
                                        fontFamily: "Poppins",
                                      }}
                                    >
                                      ₹{product.SellingPrice}
                                      {product.Mrp === product.SellingPrice ? (
                                        <> </>
                                      ) : (
                                        <>
                                          <span
                                            style={{
                                              color: "#ccc",
                                              fontSize: "12px",
                                              textDecorationLine:
                                                "line-through",
                                              paddingLeft: "1vh",
                                            }}
                                          >
                                            ₹{product.Mrp}
                                          </span>
                                        </>
                                      )}
                                    </h6>
                                    <div className="counter-box mt-1">
                                      <h6
                                        className="sold weight text-content fw-normal kg_text"
                                        style={{
                                          color: "#000",
                                          fontFamily: "Poppins",
                                          fontWeight: "bold",
                                          textTransform: "lowercase",
                                        }}
                                      >
                                        {product.PackSize.length > 6
                                          ? `${product.PackSize.slice(0, 6)}...`
                                          : product.PackSize}
                                      </h6>

                                      <div className="addtocart_btn addtocart_btn10">
                                        {product.CartQuantity > 0 ? (
                                          <>
                                            <div className="qty-box cart_qty open">
                                              <div>
                                                <div className="input-group">
                                                  <button
                                                    type="button"
                                                    style={{
                                                      backgroundColor:
                                                        "#00ab66",
                                                    }}
                                                    className="btn qty-left-minus"
                                                    data-type="minus"
                                                    data-field
                                                    onClick={(e) =>
                                                      decrementtocartPress(
                                                        e,
                                                        product,
                                                        itemIndex
                                                      )
                                                    }
                                                  >
                                                    <i
                                                      className="fa fa-minus"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#fff",
                                                      }}
                                                    />
                                                  </button>
                                                  <div
                                                    className="form-control input-number qty-input "
                                                    style={{
                                                      backgroundColor:
                                                        "#00ab66",
                                                      border: "unset",
                                                      paddingTop: "3px",
                                                    }}
                                                  >
                                                    {" "}
                                                    <span
                                                      style={{
                                                        color: "#fff",
                                                      }}
                                                    >
                                                      {product.CartQuantity}
                                                    </span>
                                                  </div>

                                                  <button
                                                    type="button"
                                                    style={{
                                                      backgroundColor:
                                                        "#00ab66",
                                                    }}
                                                    className="btn qty-right-plus"
                                                    data-type="plus"
                                                    data-field
                                                    onClick={(e) => {
                                                      if (
                                                        11 ===
                                                        product.CartQuantity
                                                      ) {
                                                        addtocartPress(
                                                          e,
                                                          product,
                                                          itemIndex
                                                        );
                                                      }
                                                    }}
                                                  >
                                                    <i
                                                      className="fa fa-plus"
                                                      aria-hidden="true"
                                                      style={{
                                                        color: "#fff",
                                                      }}
                                                    />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              className="add-button addcart-button btn buy-button text-light"
                                              onClick={(e) =>
                                                addtocartPress(
                                                  e,
                                                  product,
                                                  itemIndex
                                                )
                                              }
                                            >
                                              <h5 className="add_text">
                                                <i
                                                  style={{
                                                    fontSize: "20px",
                                                  }}
                                                  className="fa-solid fa-plus"
                                                />
                                              </h5>
                                              <i
                                                style={{
                                                  fontSize: "16px",
                                                }}
                                                className="fa-solid fa-plus add_text1"
                                              />
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              </div>
                            </div>
                          </SwiperSlide>
                        ))}
                    </>
                  </Swiper>
                </div>
              </div>
            </div>
            <Modal
              size="md"
              // centered
              isOpen={visible}
              toggle={() => setVisible(!visible)}
              modalTransition={{ timeout: 500 }}
              className={
                window.innerWidth < 780
                  ? "mobile-versionnnn-modal"
                  : "desktop-versionnnnnn-modal"
              }
            >
              <ModalBody>
                <h3>{currentProduct.ItemName}</h3>
                <h5>{currentProduct.Brand}</h5>
                <div className="col-12 mt-3 ">
                  {currentProduct &&
                    currentProduct.PackSizes.map((packsize, packIndex) => (
                      <div
                        className="col-12 d-flex justify-content-between my-2"
                        key={packsize._id}
                      >
                        <div className="col-2">
                          <img
                            src={packsize.ImgUrlDesk}
                            className="img-fluid blur-up lazyload"
                            alt="image"
                            width={200}
                          />
                        </div>
                        <h6
                          className="col-1 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            marginLeft: "2vh",
                            fontFamily: "Poppins",
                          }}
                        >
                          {packsize.PackSize}
                        </h6>
                        <h6
                          className="col-3 d-flex justify-content-center align-items-center "
                          style={{
                            color: "#000",
                            fontSize: "14px",
                            marginLeft: "2vh",
                            fontFamily: "Poppins",
                          }}
                        >
                          ₹{packsize.SellingPrice}
                          {packsize.Mrp === packsize.SellingPrice ? (
                            <> </>
                          ) : (
                            <>
                              <span
                                style={{
                                  color: "#ccc",
                                  fontSize: "12px",
                                  textDecorationLine: "line-through",
                                  marginLeft: "0.5vh",
                                }}
                              >
                                ₹{packsize.Mrp}
                              </span>
                            </>
                          )}
                        </h6>
                        <div className="col-3 d-flex justify-content-end align-items-center">
                          {packsize.OutOfStack === true ? (
                            <>
                              {" "}
                              <Button
                                variant="contained"
                                style={{
                                  color: "#fff",
                                  background: "#009900",
                                  // fontSize:"15px"
                                }}
                                className="mx-1"
                              >
                                out of stock
                              </Button>
                            </>
                          ) : (
                            <>
                              {currentProduct.PackSizes[packIndex]
                                .CartQuantity > 0 ? (
                                <>
                                  <div className="addtocart_btn">
                                    <div className="qty-box cart_qty open">
                                      <div className="input-group">
                                        <button
                                          type="button"
                                          className="btn qty-left-minus"
                                          data-type="minus"
                                          data-field
                                          onClick={(e) =>
                                            decrementtocartPress(
                                              e,
                                              currentProduct,
                                              curritemIndex,
                                              packIndex
                                            )
                                          }
                                        >
                                          <i
                                            className="fa fa-minus"
                                            aria-hidden="true"
                                          />
                                        </button>
                                        <input
                                          className="form-control input-number qty-input"
                                          type="text"
                                          name="quantity"
                                          defaultValue={0}
                                          value={
                                            currentProduct.PackSizes[packIndex]
                                              .CartQuantity
                                          }
                                          readonly="readonly"
                                        />
                                        {11 ===
                                        currentProduct.PackSizes[packIndex]
                                          .CartQuantity ? (
                                          <>
                                            <div className="qty-box cart_qty open">
                                              <div className="input-group"></div>
                                            </div>
                                            <button
                                              type="button"
                                              className="btn qty-left-plus"
                                              data-type="plus"
                                              data-field
                                            >
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              type="button"
                                              className="btn qty-left-plus"
                                              data-type="plus"
                                              data-field
                                              onClick={(e) =>
                                                addtocartPress(
                                                  e,
                                                  currentProduct,
                                                  curritemIndex,
                                                  packIndex
                                                )
                                              }
                                            >
                                              <i
                                                className="fa fa-plus"
                                                aria-hidden="true"
                                              />
                                            </button>
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <Button
                                    variant="contained"
                                    style={{
                                      color: "#fff",
                                      background: "#009900",
                                    }}
                                    className="mx-1"
                                    onClick={(e) =>
                                      addtocartPress(
                                        e,
                                        currentProduct,
                                        curritemIndex,
                                        packIndex
                                      )
                                    }
                                  >
                                    Add
                                    <span className="mx-1">
                                      {/* <i className="fa-solid fa-plus" /> */}
                                    </span>
                                  </Button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    ))}
                </div>
              </ModalBody>
            </Modal>
          </section>{" "}
        </>
      ) : (
        <>
          <div></div>
        </>
      )}
    </>
  );
};

export default RecentOrders;
