import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

const CartCheckOut = () => {
  const {
    CartItems,
    cartTotalAmount,
    ProductTotal,
    cartCouponDiscount,
    logisticsAmount,
    netPayable,
    cartTotalMrp,
    cartTotalPrice,
  } = useSelector((state) => state.Cart);

  const { isAuth } = useSelector((state) => state.Athentication);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [cartamountDetails, setCartamountDetails] = useState("");

  useEffect(() => {
    let cartTotalAmountN = 0;
    let netPayableN = 0;
    let cartTotalMrpN = 0;
    let cartTotalPriceN = 0;
    let logisticsAmountN = 40;
    const totalitem = CartItems;

    for (let i = 0; i < totalitem.length; i++) {
      cartTotalAmountN = cartTotalAmountN + CartItems[i].TotalAmount;
      cartTotalMrpN = cartTotalMrpN + CartItems[i].TotalMrp;
      cartTotalPriceN = cartTotalPriceN + CartItems[i].TotalPrice;
    }

    if (cartTotalAmountN >= 500) {
      logisticsAmountN = 0;
    } else {
      logisticsAmountN = 40;
    }
    netPayableN = cartTotalAmountN + logisticsAmountN;

    const cartAmountsForm = {
      cartTotalAmount: cartTotalAmountN,
      netPayable: netPayableN,
      cartTotalMrp: cartTotalMrpN,
      cartTotalPrice: cartTotalPriceN,
      logisticsAmount: logisticsAmountN,
    };
    setCartamountDetails(cartAmountsForm);
  }, [CartItems]);

  return (
    <>
      <div className="col-xxl-5 cart_detail_conta">
        <div className="summery-box p-sticky">
          <div className="summery-header">
            <h3>Cart Total</h3>
          </div>
          <div className="summery-contain">
            {/* <div className="coupon-cart">
              <h6 className="text-content mb-2">Coupon Apply</h6>
              <div className="mb-3 coupon-box input-group">
                <input
                  type="email"
                  className="form-control"
                  id="exampleFormControlInput1"
                  placeholder="Enter Coupon Code Here..."
                />
                <button className="btn-apply">Apply</button>
              </div>
            </div> */}
            <ul>
              <li>
                <h4 style={{ fontWeight: "600" }}>Total MRP</h4>
                <h4
                  className="price"
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  ₹{Number(cartamountDetails.cartTotalMrp)}
                </h4>
              </li>
              <li>
                <h4 style={{ fontWeight: "600" }}>Sub-Total</h4>
                <h4
                  className="price"
                  style={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontWeight: "600",
                  }}
                >
                  ₹{cartamountDetails.cartTotalAmount}
                </h4>
              </li>
              <li>
                <h4>Coupon Discount</h4>
                <h4 className="price">(-) {cartCouponDiscount}</h4>
              </li>
              <li className="align-items-start">
                <h4>Shipping</h4>
                <h4 className="price text-end">
                  ₹{cartamountDetails.logisticsAmount}
                </h4>
              </li>
              <li className="align-items-start">
                <h4>Saving</h4>
                <h4 className="price text-end">
                  - ₹{cartTotalMrp - cartTotalPrice}
                </h4>
              </li>
            </ul>
          </div>
          <ul className="summery-total">
            <li className="list-total border-top-0">
              <h4>Total (INR)</h4>
              <h4
                className="price theme-color"
                style={{ fontFamily: "Poppins" }}
              >
                ₹{cartamountDetails.netPayable}
              </h4>
            </li>
          </ul>
          <div className="button-group cart-button procestocheckmobversion">
            <ul>
              <li>
                <Link to="/checkout">
                  <button className="btn btn-animation proceed-btn fw-bold">
                    Process To Checkout
                  </button>
                </Link>
              </li>

              <li>
                <Link to="/">
                  <button className="btn btn-light shopping-button text-dark">
                    <i className="fa-solid fa-arrow-left-long" />
                    Return To Shopping
                  </button>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default CartCheckOut;
