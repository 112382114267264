import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalHeader } from "reactstrap";
import axios from "axios";

import { Baseurl } from "../../../../config/BaseUrl";
import {
  addressModelStatus,
  currAdress,
  updateAddress,
} from "../../../../redux/athentication/Athentication";

const AddAddress = () => {
  const { isAuth, loginData, clientid, address } = useSelector(
    (store) => store.Athentication
  );
    const dispatch = useDispatch();
    const [model, setModel] = useState();
    const [fname, setFname] = useState(loginData.Name);
    const [mobile, setMobile] = useState(loginData.Mobile);
    const [number, setNumber] = useState(loginData.Mobile);
    const [altmobile, setAltMobile] = useState("");

    const [houseNo, setHouseNo] = useState("");
    const [street, setStreet] = useState("");
    const [addres, setAddress] = useState("");
    const [area, setArea] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [pincode, setPinCode] = useState("");
    const [pincodecon, setPinCodecon] = useState("");
    const [addressType, setAddressType] = useState("Home");
    const [errormassage, setErrormassage] = useState("");
    const [pinerrormassage, setPinErrormassage] = useState("");
    const [landmark, setLandmark] = useState("");
    const [areaDept, setAreaDept] = useState("");
    const [mainerrormassage, setMainErrormassage] = useState("");
    const [location, setLocation] = useState({
      latitude: null,
      longitude: null,
      address: {
        AName: "",
        City: "",
        Number: "",
        Mobile: "",
        Pincode: "",
        State: "",
        HNo: "",
        StreetDet: "",
        AreaDet: "",
        LandMark: "",
        Address: "",
      },
      error: null,
    });

  // const [pincode, setSupercat] = useState("");

  // const pincodehandleChange = (e) => {
  //   const pincodevalue = e.target.value.replace(/\D/g, "");
  //   setPinErrormassage("");
  //   setPinCode(pincodevalue);
  //   if (pincodevalue.length === 6) {
  //     setPinCodecon(pincodevalue);
  //     setPinErrormassage("");
  //   } else {
  //     setPinCodecon("");
  //     setPinErrormassage("please enter currect pincode");
  //   }
  // };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    let addresss = [...address];
    if (fname !== "" && mobile !== "" && pincodecon !== "" && city !== "") {
      try {
        const formData = {
          AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
          Number: altmobile,
          Mobile: mobile,
          Pincode: pincodecon,
          State: state,
          City: city,
          HNo: houseNo,
          StreetDet: street,
          AreaDet: areaDept,
          LandMark: landmark,
          Address: addres,
          Type: addressType,
          // AName: area,
          ClientId: clientid,
        };
        console.log(formData, "formdata");

        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const url = `${Baseurl}/api/v1/client/address`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastaddress = addAddress.data.client;

          const currentAddress = [...lastaddress.Addresses];

          // dispatch(currAdress(currentAddress.Addresses[0]));
          dispatch(updateAddress(currentAddress));
          dispatch(addressModelStatus(false));
        }
      } catch (error) {}
    } else {
      setMainErrormassage("please enter currect values");
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          console.log(latitude, longitude);
          setLocation((prevState) => ({
            ...prevState,
            latitude,
            longitude,
            error: null,
          }));

          fetchAddress(latitude, longitude);
        },
        (error) => {
          if (error.code === error.PERMISSION_DENIED) {
            alert("Please allow location access in your browser settings.");
          }
          console.log("1");
          setLocation({
            latitude: null,
            longitude: null,
            error: error.message,
          });
        },
        { enableHighAccuracy: true }
      );
    } else {
      setLocation({
        latitude: null,
        longitude: null,
        error: "Geolocation is not supported by this browser.",
      });
    }
  };
  const fetchAddress = async (latitude, longitude) => {
    const apiKey = "AIzaSyAkOSe3Ns4VZ9MUqztuZj49GrUQ3mMqMag";
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );
      const singleAddress = response.data.results[0].address_components;
      // console.log(response.data.results[0].formatted_address, "response.data.results[0]");
      // console.log(singleAddress,"singleAddress34567");
      const address = {
        AName: singleAddress[3].long_name,
        City: singleAddress[6].long_name,
        Pincode: singleAddress[9].long_name,
        State: singleAddress[7].long_name,
        HNo: singleAddress[0].long_name,
        StreetDet: singleAddress[1].long_name,
        AreaDet: singleAddress[2].long_name,
        LandMark: singleAddress[3].long_name,
        Address: response.data.results[0].formatted_address,
      };
      // console.log(address, "formData123");

      setLocation((prevState) => ({
        ...prevState,
        address,
      }));
    } catch (error) {
      setLocation((prevState) => ({
        ...prevState,
        address: "Unable to fetch address",
        error: error.message,
      }));
    }

    // setAddress
  };

  useEffect(() => {
    setCity(location.address.City);
    // setNumber(location.address.Number);
    // setMobile(location.address.Mobile);
    setPinCodecon(location.address.Pincode);
    setState(location.address.State);
    setHouseNo(location.address.HNo);
    setStreet(location.address.StreetDet);
    setAreaDept(location.address.AreaDet);
    setLandmark(location.address.LandMark);
    setAddress(location.address.Address);
  }, [location]);

  return (
    <>
      <div className="header d-flex justify-content-center align-items-center">
        <h5 className="modal-title">
          <b>Add a new address</b>
        </h5>

        <button
          type="button"
          className="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          onClick={() => dispatch(addressModelStatus(false))}
        >
          <i className="fa-solid fa-xmark" />
        </button>
      </div>

      <div className="modal-body">
        {/* <div className="currentcontainer" onClick={() => getLocation()}>
          <button className="buttontext">Add Current Address</button>
        </div> */}
        <div className="row g-4">
          <div className="col-xxl-12 mt-4 m-2">
            <form>
              <div className="form-floating theme-form-floating">
                <input
                  type="text"
                  className="form-control opacity-75"
                  id="pname"
                  placeholder=" "
                  value={fname}
                  onChange={(e) => setFname(e.target.value)}
                  required
                  style={{ textTransform: "capitalize" }}
                />
                <label htmlFor="pname">Full Name</label>
              </div>
            </form>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=" "
                    name="mobile"
                    id="mobile"
                    maxLength={10}
                    minLength={10}
                    value={mobile}
                    readOnly="readOnly"
                  />
                  <label htmlFor="mobile">Mobile Number</label>
                </div>
              </form>
              <p style={{ color: "red" }}>{errormassage}</p>
            </div>

            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    className="form-control"
                    type="tel"
                    placeholder=" "
                    name="mobile"
                    id="mobile"
                    maxLength={10}
                    minLength={10}
                    value={altmobile}
                    onChange={(e) => setAltMobile(e.target.value)}
                  />
                  <label htmlFor="mobile">Alternative Number</label>
                </div>
              </form>
            </div>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={houseNo}
                    onChange={(e) => setHouseNo(e.target.value)}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">House No.</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">Street</label>
                </div>
              </form>
            </div>
          </div>

          <div className="col-xxl-12 mt-2">
            <form>
              <div className="form-floating mb-4 theme-form-floating">
                <textarea
                  className="form-control"
                  placeholder="Leave a comment here"
                  id="address"
                  style={{ height: 75, textTransform: "capitalize" }}
                  defaultValue={""}
                  value={addres}
                  onChange={(e) => setAddress(e.target.value)}
                  required
                />
                <label htmlFor="address">Enter Address</label>
              </div>
            </form>
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="landmark"
                    placeholder=" "
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="landmark">Landmark</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="areadept"
                    placeholder=" "
                    value={areaDept}
                    required
                    onChange={(e) => setAreaDept(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="areadept">Area</label>
                </div>
              </form>
            </div>
          </div>

          <div className="row-xxl-12 d-flex">
            {/* <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={area}
                    required
                    onChange={(e) => setArea(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">Area</label>
                </div>
              </form>
            </div> */}
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="state"
                    placeholder=" "
                    value={state}
                    required
                    onChange={(e) => setState(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="state">State</label>
                </div>
              </form>
            </div>
            {/* <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="pname"
                    placeholder=" "
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="pname">City</label>
                </div>
              </form>
            </div> */}
          </div>

          <div className="row-xxl-12 d-flex">
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="number"
                    className="form-control opacity-75"
                    id="pincode"
                    placeholder=" "
                    maxLength={6}
                    minLength={6}
                    value={pincodecon}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (/^\d{0,6}$/.test(value)) {
                        setPinCodecon(value);
                      }
                    }}
                  />
                  <label htmlFor="pincode">Pincode</label>
                </div>
              </form>
            </div>
            <div className="col-xxl-6 m-2">
              <form>
                <div className="form-floating theme-form-floating">
                  <input
                    type="text"
                    className="form-control opacity-75"
                    id="city"
                    placeholder=" "
                    value={city}
                    required
                    onChange={(e) => setCity(e.target.value)}
                    style={{ textTransform: "capitalize" }}
                  />
                  <label htmlFor="city">City</label>
                </div>
              </form>
            </div>
          </div>

          <div className="designAdrs">
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault1"
                value="Home"
                checked={addressType === "Home"}
                onChange={(e) => {
                  setAddressType("Home");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault1">
                Home
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault2"
                value="Office"
                checked={addressType === "Office"}
                onChange={(e) => {
                  setAddressType("Office");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault2">
                Office
              </label>
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                type="radio"
                name="flexRadioDefault"
                id="flexRadioDefault3"
                value="Others"
                checked={addressType === "Others"}
                onChange={(e) => {
                  setAddressType("Others");
                }}
              />
              <label className="form-check-label" htmlFor="flexRadioDefault3">
                Others
              </label>
            </div>
          </div>
        </div>
      </div>
      <p style={{ color: "red" }}>{mainerrormassage}</p>
      <div className="modal-footer">
        <button
          type="button"
          className="btn bg-danger text-light btn-md"
          data-bs-dismiss="modal"
          onClick={() => dispatch(addressModelStatus(false))}
        >
          Close
        </button>
        <button
          type="button"
          className="btn theme-bg-color btn-md text-white"
          data-bs-dismiss="modal"
          onClick={(e) => onSubmitHandler(e)}
        >
          Save Address
        </button>
      </div>
    </>
  );
};

export default AddAddress;
