import React, { useState, useEffect } from "react";
import { FaChevronRight, FaPlus } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox } from "antd";
import { MdModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import {
  addressModelStatus,
  currAdress,
  noaddressUpdate,
  updateAddress,
} from "../../../redux/athentication/Athentication";

const EditAddress = ({ editableAddress, change_upadte_box }) => {
  const { isAuth, loginData, clientid, address } = useSelector(
    (store) => store.Athentication
  );
  const dispatch = useDispatch();
  const [model, setModel] = useState();
  const [editmodel, setEditModel] = useState(false);
  const [show_add_to_address, setshow_add_to_address] = useState(false);
  const [fname, setFname] = useState(loginData.Name);
  const [mobile, setMobile] = useState(loginData.Mobile);
  const [number, setNumber] = useState(loginData.Mobile);
  const [altmobile, setAltMobile] = useState("");

  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [mainerrormassage, setMainErrormassage] = useState("");
  const [location, setLocation] = useState({
    latitude: null,
    longitude: null,
    address: {
      AName: "",
      City: "",
      Number: "",
      Mobile: "",
      Pincode: "",
      State: "",
      HNo: "",
      StreetDet: "",
      AreaDet: "",
      LandMark: "",
      Address: "",
    },
    error: null,
  });
  const [butn_press, setbutn_press] = useState(false);
  const [addressId, setAddressId] = useState("");

  useEffect(() => {
    if (editableAddress) {
      setAddressId(editableAddress?.address._id);
      setFname(editableAddress?.address.AName);
      setMobile(editableAddress?.address.Mobile);
      setNumber(editableAddress?.address.Number);
      setAltMobile(editableAddress?.address.Mobile);
      setHouseNo(editableAddress?.address.HNo);
      setStreet(editableAddress?.address.StreetDet);
      setAddress(editableAddress?.address?.Address);
      // setArea(editableAddress?.address)
      setCity(editableAddress?.address.City);
      setState(editableAddress?.address.State);
      setPinCode(editableAddress?.address.Pincode);
      setPinCodecon(editableAddress?.address.Pincode);
      setAddressType(editableAddress?.address.Type);
      setLandmark(editableAddress?.address.LandMark);
      setAreaDept(editableAddress?.address.AreaDet);
    }
  }, [editableAddress]);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    setbutn_press(true);
    let addresss = [...address];
    if (mobile !== "" && pincodecon !== "") {
      try {
        const formData = {
          AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
          Number: mobile,
          Mobile: altmobile,
          Pincode: pincodecon,
          State: state,
          City: city,
          HNo: houseNo,
          StreetDet: street,
          AreaDet: areaDept,
          LandMark: landmark,
          Address: addres,
          Type: addressType,
          ClientId: clientid,
          addressId: addressId,
        };
        const config = {
          Headers: { "Content-Type": "application/json" },
        };

        const url = `${Baseurl}/api/v1/client/updateclientsingleaddress`;
        const addAddress = await axios.put(url, formData, config);

        if (addAddress.data.success) {
          const lastaddress = addAddress.data.client;
          const currentAddress = lastaddress.Addresses;
          dispatch(updateAddress(currentAddress));
          change_upadte_box();
          setbutn_press(false);
        }
      } catch (error) {}
    } else {
      setMainErrormassage("please enter currect values");
    }
  };

  return (
    <>
      <div className="address_add_container">
        <h4 className="address_header_text">ADD A NEW ADDRESS</h4>
        <div className="address_input_conatiner">
          <div className="address_input_box_container">
            <TextField
              label="Name"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={fname}
              onChange={(e) =>
                setFname(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
              error={fname === "" && butn_press ? true : false}
              helperText={fname === "" && butn_press ? "Please fill data" : ""}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="10-digit mobile number"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={mobile}
              onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
              error={mobile.length !== 10 && butn_press ? true : false}
              helperText={
                mobile.length !== 10 && butn_press
                  ? "Please enter valid number"
                  : ""
              }
              inputProps={{
                maxLength: 10,
                minLength: 10,
              }}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="Pincode"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={pincodecon}
              onChange={(e) => {
                const value = e.target.value;
                if (/^\d{0,6}$/.test(value)) {
                  setPinCodecon(value);
                }
              }}
              error={pincodecon.length !== 6 && butn_press ? true : false}
              helperText={
                pincodecon.length !== 6 && butn_press
                  ? "Please enter valid number"
                  : ""
              }
              inputProps={{
                maxLength: 6,
                minLength: 6,
              }}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="Locality"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={areaDept}
              onChange={(e) =>
                setAreaDept(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
              error={areaDept === "" && butn_press ? true : false}
              helperText={
                areaDept === "" && butn_press ? "Please fill data" : ""
              }
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="Address (Area and Street)"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={street}
              onChange={(e) =>
                setStreet(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
              error={street === "" && butn_press ? true : false}
              helperText={street === "" && butn_press ? "Please fill data" : ""}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="City/District/Town"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={city}
              onChange={(e) =>
                setCity(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
              error={city === "" && butn_press ? true : false}
              helperText={city === "" && butn_press ? "Please fill data" : ""}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="State"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={state}
              onChange={(e) =>
                setState(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
              error={state === "" && butn_press ? true : false}
              helperText={state === "" && butn_press ? "Please fill data" : ""}
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="Landmark (Optional)"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={landmark}
              onChange={(e) =>
                setLandmark(
                  e.target.value.replace(/\b\w/g, (char) => char.toUpperCase())
                )
              }
            />
          </div>
          <div className="address_input_box_container">
            <TextField
              label="Alternate Phone (Optional)"
              id="outlined-size-small"
              size="small"
              className="address_input_box"
              value={altmobile}
              onChange={(e) => setAltMobile(e.target.value.replace(/\D/g, ""))}
            />
          </div>
          <div className="address_input_box_container"></div>
          <div className="">
            <FormControl className="address_input_box">
              <FormLabel id="demo-row-radio-buttons-group-label">
                Address Type
              </FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-row-radio-buttons-group-label"
                name="row-radio-buttons-group"
              >
                <FormControlLabel
                  value="Home"
                  control={<Radio />}
                  label="Home"
                  checked={addressType === "Home"}
                  onChange={(e) => {
                    setAddressType("Home");
                  }}
                />
                <FormControlLabel
                  value="Office"
                  control={<Radio />}
                  label="Office"
                  checked={addressType === "Office"}
                  onChange={(e) => {
                    setAddressType("Office");
                  }}
                />
                <FormControlLabel
                  value="Others"
                  control={<Radio />}
                  label="Others"
                  checked={addressType === "Others"}
                  onChange={(e) => {
                    setAddressType("Others");
                  }}
                />
              </RadioGroup>
            </FormControl>
          </div>
          <div className="button_containers_add">
            <button
              className="add_address_btn"
              onClick={(e) => onSubmitHandler(e)}
            >
              Update
            </button>
            <button
              className="cancel_address_btn"
              onClick={() => {
                change_upadte_box();
                setbutn_press(false);
              }}
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditAddress;
