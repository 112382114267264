import React from "react";
import Header from "../layout/Header";
import { IoBagHandle } from "react-icons/io5";
import { RiArrowRightSLine } from "react-icons/ri";
import { FaChevronRight } from "react-icons/fa6";
import { FaUserAlt } from "react-icons/fa";
import { HiLocationMarker } from "react-icons/hi";
import Address from "./dashboard_new/Address";
import { IoBagHandleOutline } from "react-icons/io5";
import { FaRegUser } from "react-icons/fa";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { Link, Outlet, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../redux/athentication/Athentication";
import { clearOrder } from "../../redux/order/OrderSlice";
import { BsCart3 } from "react-icons/bs";
import { BiCategoryAlt } from "react-icons/bi";
import { BsArrowRepeat } from "react-icons/bs";
import { FaRegUserCircle } from "react-icons/fa";
import { FaRegHeart } from "react-icons/fa";
import { BiWallet } from "react-icons/bi";

const Dashboard_new = () => {
  const { isAuth, name, loginData } = useSelector(
    (state) => state.Athentication
  );
  const { CartItems } = useSelector((state) => state.Cart);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const locationpath = useLocation();
  const logout = () => {
    dispatch(authActions.signout());
    dispatch(clearOrder());
    navigate("/");
  };
  return (
    <>
      <div className="only_in-desktop_description">
        <Header />
      </div>

      <div className="account_dashb_main">
        <div className="account_dashb_box">
          <div className="account_dashb_box_left">
            <div className="dashb_box_left_user_detail_box">
              <div className="left_user_detail_box_image_b">
                <img
                  src="../assets/images/ProfileIcon.svg"
                  className="blur-up lazyload update_img text-center"
                  alt="img"
                />
              </div>
              <div className="left_user_detail_box_name_b">
                <h4 className="text-h4name">{name}</h4>
                <p className="text-pname">+91 {loginData.Mobile}</p>
              </div>
            </div>
            <div className="wallet_card">
              <div
                style={{
                  display: "flex",
                  gap: "0.5vh",
                }}
              >
                <p className="text-pname">Valid till</p>
                <h4 className="text-h4name">04 Jan</h4>
              </div>

              <div className=""></div>
            </div>
            <div className="acc_box_left">
              <Link to="/account">
                <div
                  className={
                    locationpath.pathname === "/account"
                      ? "dashb_box_left_acc_detail_box_active"
                      : "dashb_box_left_acc_detail_box"
                  }
                >
                  <div className="dashb_box_left_acc_detail_box_icon">
                    <IoBagHandleOutline className="acc_left_d_icon" />
                  </div>
                  <div className="dashb_box_left_acc_detail_box_text">
                    <h4>my Orders</h4>
                  </div>
                </div>
              </Link>
              <Link to="/account/address">
                <div
                  className={
                    locationpath.pathname === "/account/address"
                      ? "dashb_box_left_acc_detail_box_active"
                      : "dashb_box_left_acc_detail_box"
                  }
                >
                  <div className="dashb_box_left_acc_detail_box_icon">
                    <MdOutlineAddLocationAlt className="acc_left_d_icon" />
                  </div>
                  <div className="dashb_box_left_acc_detail_box_text">
                    <h4>manage addresses</h4>
                  </div>
                </div>
              </Link>

              <Link to="/account/profile">
                <div
                  className={
                    locationpath.pathname === "/account/profile"
                      ? "dashb_box_left_acc_detail_box_active"
                      : "dashb_box_left_acc_detail_box"
                  }
                >
                  <div className="dashb_box_left_acc_detail_box_icon">
                    <FaRegUserCircle className="acc_left_d_icon" />
                  </div>
                  <div className="dashb_box_left_acc_detail_box_text">
                    <h4>Profile Information</h4>
                  </div>
                </div>
              </Link>
              <Link to="/account/refferals">
                <div
                  className={
                    locationpath.pathname === "/account/refferals"
                      ? "dashb_box_left_acc_detail_box_active"
                      : "dashb_box_left_acc_detail_box"
                  }
                >
                  <div className="dashb_box_left_acc_detail_box_icon">
                    <FaRegHeart className="acc_left_d_icon" />
                  </div>
                  <div className="dashb_box_left_acc_detail_box_text">
                    <h4>Manage referrals</h4>
                  </div>
                </div>
              </Link>
              <Link to="/account/wallet">
                <div
                  className={
                    locationpath.pathname === "/account/wallet"
                      ? "dashb_box_left_acc_detail_box_active"
                      : "dashb_box_left_acc_detail_box"
                  }
                >
                  <div className="dashb_box_left_acc_detail_box_icon">
                    <BiWallet className="acc_left_d_icon" />
                  </div>
                  <div className="dashb_box_left_acc_detail_box_text">
                    <h4>Wallet </h4>
                  </div>
                </div>
              </Link>

              <div
                className="dashb_box_left_acc_detail_box"
                onClick={(e) => logout(e)}
              >
                <div className="dashb_box_left_acc_detail_box_icon">
                  <CiLogout className="acc_left_d_icon" />
                </div>
                <div className="dashb_box_left_acc_detail_box_text">
                  <h4>Log Out</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="account_dashb_box_right">
            <Outlet />
          </div>
        </div>
      </div>

      {/* ======mobile menu start====== */}
      <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
          <li className="active">
            <Link to="/">
              <a>
                <Link to="/">
                  <img
                    src="../assets/images/kisanlogo3.jpg"
                    alt="img"
                    height="21px"
                    width="40px"
                  />
                </Link>
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                >
                  Home
                </span>
              </a>
            </Link>
          </li>
          <li className="mobile-category">
            <Link to="/CategoryScreen">
              <a>
                <BiCategoryAlt
                  style={{ color: "#404040", fontWeight: "600" }}
                  className="iconly-Category icli js-link"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Category
                </span>
              </a>
            </Link>
          </li>

          <li>
            <Link to="">
              <a className="notifi-wishlist">
                <BsArrowRepeat
                  style={{
                    color: "#404040",
                    fontWeight: "600",
                    fontSize: "21px",
                  }}
                  className="iconly-Heart icli"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Order Again
                </span>
              </a>
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <div className="onhover-dropdown header-badge">
                <a className="position-relative header-wishlist">
                  <BsCart3
                    style={{ color: "#404040", fontWeight: "600" }}
                    className="iconly-Bag-2 icli fly-cate"
                  />
                  <span
                    style={{
                      color: "#767676",
                      fontWeight: "500",
                      fontSize: "11px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Cart
                  </span>
                  <span
                    className="position-absolute  translate-middle badge "
                    style={{
                      top: "5px",
                      left: "55px",
                      backgroundColor: "#f6f6f6",
                      color: "#04ab66",
                    }}
                  >
                    {CartItems.length}
                  </span>
                </a>
              </div>
            </Link>
          </li>
          <li>
            <li>
              {isAuth ? (
                <Link to="/account">
                  <a style={{ color: "#000" }} className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />

                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              ) : (
                <Link to="/login">
                  <a className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              )}
            </li>
          </li>
        </ul>
      </div>
      {/* ======mobile menu end====== */}
    </>
  );
};

export default Dashboard_new;
