import React from "react";
import { Link } from "react-router-dom";
import moment from "moment/moment";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { useSelector } from "react-redux";

const Orders = () => {
  const { loginData, clientid } = useSelector((store) => store.Athentication);
  const { clientOrder } = useSelector((state) => state.Order);

  return (
    <>
      {clientOrder &&
        clientOrder.map((order, index) => (
          <Link to={`/ordertracking/${order._id}`} key={index}>
            <div className="order_container" key={index}>
              <div className="image_order_cont">
                {order.OrderProducts.map((image_ord, img_order) => (
                  <>
                    <img
                      src={image_ord.ImgUrl}
                      className="blur-up lazyload update_img text-center"
                      alt="img"
                      key={img_order}
                    />
                  </>
                ))}
              </div>
              <h5 className="order_l_status">
                {order.StatusText}{" "}
                <IoCheckmarkCircle className="order_del_ico" />
              </h5>
              <div className="price_container">
                <p>
                  Placed at{" "}
                  {moment(order.createdAt).format("DD MMM YY, hh:ss A")}
                </p>
                <h5>
                  ₹{order.GrandTotal}{" "}
                  <MdChevronRight className="order_right_ico" />
                </h5>
              </div>
            </div>
          </Link>
        ))}
    </>
  );
};

export default Orders;
