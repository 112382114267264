import React from "react";
import { GiInfo } from "react-icons/gi";
import { FaGreaterThan } from "react-icons/fa";

const Wallet = () => {
  return (
    <>
      <div className="refferal_container">
        <div
          className="refferral_head"
          style={{
            background: "linear-gradient(to right, #00ab66, #00cc7a,#00ff99)",
            height: "11vh",
          }}
        ></div>

        <div className="wallet_main">
          <div className="wallet_middle">
            <div className="wallet_body">
              <div className="wallet_body_text">₹ 0</div>
              <div className="wallet_body_text1">Your Balance</div>
            </div>
            <div className="wallet_body">
              <img
                src="/assets/images/wallet.png"
                className="wallet_body_wpimg"
              />
            </div>
          </div>
        </div>

        <div className="wallet_body_lastcon">
          <div className="wallet_body_recent">
            <div className="wallet_recent_text">Recent Activity</div>
            <div className="wallet_see_main">
              <div className="wallet_seetext">See all</div>
              <div className="wallet_seeicon">
                <FaGreaterThan />
              </div>
            </div>
          </div>

          <div className="wallet_activity_history">
            <div className="wallet_topcon">
              <div className="wallet_topicon">
                <GiInfo />
              </div>
              <div className="wallet_textcon">
                <div className="wallet_textcon1">Free Cash Expired</div>
                <div className="wallet_textdate">08/12/2024 at 01:37am</div>
              </div>
            </div>
            <div className="wallet_bottomcon">
              <div className="wallet_bottomtext">₹75</div>
              <div className="wallet_bottomexpiretext">Expires 08/12/2024</div>
            </div>
          </div>
          <div className="wallet_activity_history">
            <div className="wallet_topcon">
              <div className="wallet_topicon">
                <GiInfo />
              </div>
              <div className="wallet_textcon">
                <div className="wallet_textcon1">Free Cash Expired</div>
                <div className="wallet_textdate">08/12/2024 at 01:37am</div>
              </div>
            </div>
            <div className="wallet_bottomcon">
              <div className="wallet_bottomtext">₹75</div>
              <div className="wallet_bottomexpiretext">Expires 08/12/2024</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
