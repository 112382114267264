import React, { useState, useEffect, useRef } from "react";
import { FaChevronRight, FaPlus } from "react-icons/fa6";
import { styled } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { Checkbox } from "antd";
import { MdModeEditOutline } from "react-icons/md";
import { MdDeleteOutline } from "react-icons/md";
import { IoCheckmarkCircle } from "react-icons/io5";
import { MdChevronRight } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Baseurl } from "../../../config/BaseUrl";
import axios from "axios";
import {
  addressModelStatus,
  currAdress,
  noaddressUpdate,
  updateAddress,
} from "../../../redux/athentication/Athentication";
import EditAddress from "./EditAddress";
import { FaSearch } from "react-icons/fa";
import { SearchOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { Button, Modal, Input } from "antd";

const Address = () => {
  const {
    isAuth,
    loginData,
    currentAddress,
    address,
    addressmodel,
    editaddressmodel,
    clientid,
  } = useSelector((store) => store.Athentication);
  const { SelectedCity } = useSelector((store) => store.Cart);

  const dispatch = useDispatch();
  const locationpath = useLocation();
  const mapRef = useRef(null);
  const [editmodel, setEditModel] = useState(false);
  const [editableAddress, setEditableAddress] = useState(null);
  const [show_add_to_address, setshow_add_to_address] = useState(false);
  const [fname, setFname] = useState(loginData.Name);
  const [mobile, setMobile] = useState(loginData.Mobile);
  const [number, setNumber] = useState(loginData.Mobile);
  const [altmobile, setAltMobile] = useState(loginData.Mobile);

  const [houseNo, setHouseNo] = useState("");
  const [street, setStreet] = useState("");
  const [addres, setAddress] = useState("");
  const [area, setArea] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPinCode] = useState("");
  const [pincodecon, setPinCodecon] = useState("");
  const [addressType, setAddressType] = useState("Home");
  const [errormassage, setErrormassage] = useState("");
  const [pinerrormassage, setPinErrormassage] = useState("");
  const [landmark, setLandmark] = useState("");
  const [areaDept, setAreaDept] = useState("");
  const [mainerrormassage, setMainErrormassage] = useState("");
  const [location, setLocation] = useState({ lat: 17.444287, lng: 78.4327336 });
  const [mapLoaded, setMapLoaded] = useState(false);
  const [butn_press, setbutn_press] = useState(false);

  const [modal1Open, setModal1Open] = useState(false);

  const [locations, setLocations] = useState({
    latitude: null,
    longitude: null,
  });
  const [addresss, setAddresss] = useState("");

  const [query, setQuery] = useState("");
  const [results, setResults] = useState([]);

  const handleSearch = async (e, value) => {
    e.preventDefault();

    try {
      const API_KEY = "AIzaSyD9On5PRHixcY4UO4T-0DowNTcDyBhMrlI";
      const url = `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${encodeURIComponent(
        value
      )}&key=${API_KEY}&sessiontoken=1234567890`;

      const response = await axios.get(url);

      setResults(response.data.predictions);
    } catch (error) {
      console.error("Error fetching address suggestions:", error);
    }
  };

  useEffect(() => {
    // Ensure the map is initialized only when the DOM element is ready
    if (mapRef.current && !mapLoaded) {
      loadGoogleMaps(() => {
        initializeMap();
        setMapLoaded(true); // Mark the map as loaded
      });
    }
  }, [mapRef.current]);

  const loadGoogleMaps = (callback) => {
    // Check if the script is already loaded
    if (window.google && window.google.maps) {
      callback();
      return;
    }

    // Check if a script is already being loaded
    if (document.getElementById("google-maps-script")) {
      const existingScript = document.getElementById("google-maps-script");
      existingScript.addEventListener("load", callback);
      return;
    }
    const apiKey = "AIzaSyD9On5PRHixcY4UO4T-0DowNTcDyBhMrlI";
    // Load the Google Maps script
    const script = document.createElement("script");
    script.id = "google-maps-script";
    script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
    script.async = true;
    script.onload = callback;
    document.body.appendChild(script);
  };

  const initializeMap = () => {
    if (!mapRef.current) return;
    // Initialize map
    const map = new window.google.maps.Map(mapRef.current, {
      center: location,
      zoom: 12,
    });

    // Create draggable marker
    const marker = new window.google.maps.Marker({
      position: location,
      map: map,
      draggable: true,
    });

    // Update location on marker drag
    marker.addListener("dragend", (event) => {
      const newLocation = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
      setLocation(newLocation);
      fetchAddress(newLocation.lat, newLocation.lng);
    });
  };

  const fetchAddress = async (latitude, longitude) => {
    try {
      const apiKey = "AIzaSyD9On5PRHixcY4UO4T-0DowNTcDyBhMrlI";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );

      if (response.data.status !== "OK") {
        throw new Error("Error fetching data from Google Maps API");
      }

      const addressComponents =
        response.data.results[0]?.address_components || [];

      const getAddressComponent = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          ?.long_name || "";

      const addressge = {
        rodeno: getAddressComponent("route")
          ? `${getAddressComponent("route") + ", "}`
          : "",
        sublocality2: getAddressComponent("sublocality_level_2")
          ? `${getAddressComponent("sublocality_level_2") + ", "}`
          : "",
        sublocality1: getAddressComponent("sublocality_level_1")
          ? `${getAddressComponent("sublocality_level_1")}`
          : "",
        pinCode: getAddressComponent("postal_code"),
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        country: getAddressComponent("country"),
        locality:
          getAddressComponent("sublocality") ||
          getAddressComponent("sublocality_level_1"),
      };
      setAddresss(addressge);
      setPinCodecon(addressge.pinCode);
      setAreaDept(`${addressge.sublocality1}${addressge.locality}`);
      setCity(addressge.city);
      setState(addressge.state);
      setStreet(`${addressge.rodeno}${addressge.sublocality2}`);
      return address;
    } catch (error) {
      console.error("Error fetching address: ", error.message);
      return null;
    }
  };
  // current location google api

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          // console.log(position.coords, "position.coords");
          const { latitude, longitude } = position.coords;

          const loclat = {
            latitude: latitude,
            longitude: longitude,
          };
          setLocations(loclat);
          fetchAddress(latitude, longitude);

          const map_lat = {
            lat: Number(latitude),
            lng: Number(longitude),
          };

          setLocation(map_lat);
        },
        (error) => {
          console.error("Error Code = " + error.code + " - " + error.message);
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  useEffect(() => {
    if (address.length === 0) {
      setshow_add_to_address(true);
      getLocation();
    }
  }, [address]);

  const change_upadte_box = () => {
    setEditModel(false);
    setEditableAddress(null);
  };

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setMainErrormassage("");
    setbutn_press(true);
    let addresss = [...address];
    if (
      fname !== "" &&
      mobile.length === 10 &&
      pincodecon.length === 6 &&
      city !== "" &&
      street !== "" &&
      state !== "" &&
      houseNo !== "" &&
      areaDept !== ""
    ) {
      if (city === SelectedCity) {
        try {
          const formData = {
            AName: fname.charAt(0).toUpperCase() + fname.slice(1).toLowerCase(),
            Number: altmobile,
            Mobile: mobile,
            Pincode: pincodecon,
            State: state,
            City: city,
            HNo: houseNo,
            StreetDet: street,
            AreaDet: areaDept,
            LandMark: landmark,
            Address: addres,
            Type: addressType,
            // AName: area,
            ClientId: clientid,
            longitude: String(locations.latitude),
            latitude: String(locations.longitude),
          };
          const config = {
            Headers: { "Content-Type": "application/json" },
          };

          const url = `${Baseurl}/api/v1/client/address`;
          const addAddress = await axios.put(url, formData, config);

          if (addAddress.data.success) {
            const lastaddress = addAddress.data.client;

            const currentAddress = [...lastaddress.Addresses];

            // dispatch(currAdress(currentAddress.Addresses[0]));
            dispatch(updateAddress(currentAddress));
            setbutn_press(false);
            setPinCodecon("");
            setAreaDept("");
            setStreet("");
            setCity("");
            setState("");
            setLandmark("");
            setAltMobile("");
            setHouseNo("");
            setshow_add_to_address(false);
            setMapLoaded(false);
            setLocation({ lat: 17.444287, lng: 78.4327336 });
          }
        } catch (error) {}
      } else {
        toast.error("Order not Delivering this PINCODE", {
          position: "top-center",
          autoClose: 2000,
        });
      }
    } else {
      setMainErrormassage("please enter currect values");
    }
  };

  const selectclintddress = (evt, data) => {
    dispatch(currAdress(data));
  };

  const deleteClick = async (e, addressId) => {
    e.preventDefault();
    try {
      const formData = {
        ClientId: clientid,
        addressId: addressId,
      };
      const config = {
        Headers: { "Content-Type": "application/json" },
      };

      const url = `${Baseurl}/api/v1/client/deleteclientsingleaddress`;
      const addAddress = await axios.put(url, formData, config);

      if (addAddress.data.success) {
        const lastaddress = addAddress.data.client;
        const currentAddress = lastaddress.Addresses;
        dispatch(updateAddress(currentAddress));
        if (currentAddress.length === 0) {
          dispatch(noaddressUpdate());
        }
      }
    } catch (error) {}
  };

  const editaddress = (e, data) => {
    const formData = {
      address: data,
    };
    setEditableAddress(formData);
    setEditModel(true);
  };

  const fetchPlaceDetails = async (placeId) => {
    try {
      const API_KEY = "AIzaSyD9On5PRHixcY4UO4T-0DowNTcDyBhMrlI";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/place/details/json?place_id=${placeId}&key=${API_KEY}`
      );
      return response.data.result.geometry.location;
    } catch (error) {
      console.error("Error fetching place details:", error);
      return null;
    }
  };

  const handlePlacePress = async (placeId) => {
    const location = await fetchPlaceDetails(placeId);
    if (location) {
      fetchAddress(location.lat, location.lng);
      const loclat = {
        latitude: location.lat,
        longitude: location.lng,
      };
      setLocations(loclat);
      const map_lat = {
        lat: Number(location.lat),
        lng: Number(location.lng),
      };
      setLocation(map_lat);
      setModal1Open(false);
    }
  };

  
  
  return (
    <>
      <Modal
        title="Search Your Address"
        style={{
          top: 20,
        }}
        open={modal1Open}
        footer={null}
        onCancel={() => setModal1Open(false)}
      >
        <Input
          type="text"
          value={query}
          onChange={(e) => {
            setQuery(e.target.value);
            handleSearch(e, e.target.value.trim());
          }}
          placeholder="Enter address"
        />
        {results.map((result, index) => (
          <p key={index} onClick={() => handlePlacePress(result.place_id)}>
            {result.description}
          </p>
        ))}
      </Modal>

      {locationpath.pathname !== "/checkout" && (
        <h4 className="dash_header">Manage Addresses </h4>
      )}

      {!show_add_to_address && (
        <div className="address_add_container">
          <div
            className="address_box_btn"
            onClick={() => {
              setshow_add_to_address(true);
              getLocation();
            }}
          >
            <FaPlus className="acc_right_d_icon" />
            <h4 className="address_header_text">ADD A NEW ADDRESS</h4>
          </div>
        </div>
      )}

      {show_add_to_address || address.length === 0 ? (
        <div className="address_add_container">
          <h4 className="address_header_text">ADD A NEW ADDRESS</h4>
          <Button
            type="primary"
            style={{
              width: "20vh",
            }}
            icon={<SearchOutlined />}
            iconPosition="end"
            onClick={() => setModal1Open(true)}
          >
            Search
          </Button>
          <div ref={mapRef} style={{ width: "100%", height: "30vh" }}></div>

          <div className="address_input_conatiner">
            <div className="address_input_box_container">
              <TextField
                label="House No / Flat Name"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={houseNo}
                onChange={(e) =>
                  setHouseNo(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={houseNo === "" && butn_press ? true : false}
                helperText={
                  houseNo === "" && butn_press ? "Please fill data" : ""
                }
              />
            </div>
            <div className="address_input_box_container">
              <TextField
                label="Address (Area and Street)"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={street}
                onChange={(e) =>
                  setStreet(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={street === "" && butn_press ? true : false}
                helperText={
                  street === "" && butn_press ? "Please fill data" : ""
                }
              />
            </div>
            <div className="address_input_box_container">
              <TextField
                label="Locality"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={areaDept}
                onChange={(e) =>
                  setAreaDept(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={areaDept === "" && butn_press ? true : false}
                helperText={
                  areaDept === "" && butn_press ? "Please fill data" : ""
                }
              />
            </div>
            <div className="address_input_box_container">
              <TextField
                label="City/District/Town"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={city}
                onChange={(e) =>
                  setCity(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={city === "" && butn_press ? true : false}
                helperText={city === "" && butn_press ? "Please fill data" : ""}
              />
            </div>
            <div className="address_input_box_container">
              <TextField
                label="Pincode"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={pincodecon}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d{0,6}$/.test(value)) {
                    setPinCodecon(value);
                  }
                }}
                error={pincodecon.length !== 6 && butn_press ? true : false}
                helperText={
                  pincodecon.length !== 6 && butn_press
                    ? "Please enter valid number"
                    : ""
                }
                inputProps={{
                  maxLength: 6,
                  minLength: 6,
                }}
              />
            </div>
            {/* <div className="address_input_box_container">
              <TextField
                label="State"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={state}
                onChange={(e) =>
                  setState(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={state === "" && butn_press ? true : false}
                helperText={
                  state === "" && butn_press ? "Please fill data" : ""
                }
              />
            </div> */}
            <div className="address_input_box_container">
              <TextField
                label="Landmark (Optional)"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={landmark}
                onChange={(e) =>
                  setLandmark(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
              />
            </div>
            {/* <div className="address_input_box_container">
              <TextField
                label="Alternate Phone (Optional)"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={altmobile}
                onChange={(e) =>
                  setAltMobile(e.target.value.replace(/\D/g, ""))
                }
              />
            </div> */}
            <div className="address_input_box_container">
              <TextField
                label="Name"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={fname}
                onChange={(e) =>
                  setFname(
                    e.target.value.replace(/\b\w/g, (char) =>
                      char.toUpperCase()
                    )
                  )
                }
                error={fname === "" && butn_press ? true : false}
                helperText={
                  fname === "" && butn_press ? "Please fill data" : ""
                }
              />
            </div>
            <div className="address_input_box_container">
              <TextField
                label="10-digit mobile number"
                id="outlined-size-small"
                size="small"
                className="address_input_box"
                value={mobile}
                onChange={(e) => setMobile(e.target.value.replace(/\D/g, ""))}
                error={mobile.length !== 10 && butn_press ? true : false}
                helperText={
                  mobile.length !== 10 && butn_press
                    ? "Please enter valid number"
                    : ""
                }
                inputProps={{
                  maxLength: 10,
                  minLength: 10,
                }}
              />
            </div>

            <div className="">
              <FormControl className="">
                <FormLabel id="demo-row-radio-buttons-group-label">
                  Address Type
                </FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <FormControlLabel
                    value="Home"
                    control={<Radio />}
                    label="Home"
                    checked={addressType === "Home"}
                    onChange={(e) => {
                      setAddressType("Home");
                    }}
                  />
                  <FormControlLabel
                    value="Office"
                    control={<Radio />}
                    label="Office"
                    checked={addressType === "Office"}
                    onChange={(e) => {
                      setAddressType("Office");
                    }}
                  />
                  <FormControlLabel
                    value="Others"
                    control={<Radio />}
                    label="Others"
                    checked={addressType === "Others"}
                    onChange={(e) => {
                      setAddressType("Others");
                    }}
                  />
                </RadioGroup>
              </FormControl>
            </div>
            <div className="button_containers_add">
              <button
                className="add_address_btn"
                onClick={(e) => onSubmitHandler(e)}
              >
                SAVE
              </button>
              <button
                className="cancel_address_btn"
                onClick={() => {
                  setshow_add_to_address(false);
                  setbutn_press(false);
                  setPinCodecon("");
                  setAreaDept("");
                  setStreet("");
                  setCity("");
                  setState("");
                  setLandmark("");
                  setAltMobile("");
                  setMapLoaded(false);
                  setLocation({ lat: 17.444287, lng: 78.4327336 });
                }}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      {editmodel && (
        <EditAddress
          editableAddress={editableAddress}
          change_upadte_box={change_upadte_box}
        />
      )}
      {/* {address && address.map((data, index))} */}
      {address.length > 0 && (
        <div className="address_add_container">
          {address &&
            address
              .filter((data) => data._id !== editableAddress?.address._id)
              .map((data, index) => (
                <div className="address_box_containder_vi">
                  <div className="address_b_left">
                    {/* <Checkbox onChange={onChange} /> */}
                    <FormControlLabel
                      control={<Radio />}
                      checked={currentAddress._id === data._id}
                      onChange={(e) => {
                        selectclintddress(e, data);
                      }}
                      value={data._id}
                    />
                  </div>
                  <div className="address_b_middle">
                    <h5>{data.Type}</h5>
                    <p>
                      {`${data?.StreetDet},${data?.AreaDet},${data?.City},${data?.State},${data?.Pincode}`}
                    </p>
                  </div>
                  <div className="address_b_right">
                    <MdModeEditOutline
                      className="ls_icon"
                      onClick={(e) => editaddress(e, data)}
                    />
                    <MdDeleteOutline
                      className="ls_icon"
                      onClick={(e) => deleteClick(e, data._id)}
                    />
                  </div>
                </div>
              ))}
        </div>
      )}
    </>
  );
};

export default Address;
