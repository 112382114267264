import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import HomeSlider from "./Home/HomeSlider";
import BestOffers from "./Home/BestOffers";
import MonthelyEssential from "./Home/MonthelyEssential";
import HomeCategory from "./Home/HomeCategory";
import HotProducts from "./Home/HotProducts";
import InfocousToday from "./Home/InfocousToday";
import AllCategories from "./Home/AllCategories";
import FeatureesThisweek from "./Home/FeatureesThisweek";
import TodayFeature from "./Home/TodayFeature";
import HotDeals from "./Home/HotDeals";
import NonVegCategories from "./Home/NonVegCategories";
import SupermartCategories from "./Home/SupermartCategories";
import CommingSoon from "./CommingSoon";
import RecommandedProducts from "./Home/RecommandedProducts";
import TrendingProducts from "./Home/TrendingProducts";
import OfferProducts from "./Home/OfferProducts";
import DryfruitsoilsandmasalasSubCats from "./Home/DryfruitsoilsandmasalasSubCats";
import DryFruifsSubcat from "./Home/subcategory/DryFruifsSubcat";
import RiceDalSubcat from "./Home/subcategory/RiceDalSubcat";
import Dairyandbread from "./Home/subcategory/Dairyandbread";
import InstantfoodSubcat from "./Home/subcategory/InstantfoodSubcat";
import ChocolatesdessertsSubcat from "./Home/subcategory/ChocolatesdessertsSubcat";
import MunchiesSubcat from "./Home/subcategory/MunchiesSubcat";
import ColddrinksandjuicesSubcat from "./Home/subcategory/ColddrinksandjuicesSubcat";
import CerealsandbreakfastSubcat from "./Home/subcategory/CerealsandbreakfastSubcat";
import SaucesandspreadsSubcat from "./Home/subcategory/SaucesandspreadsSubcat";
import TeacoffeeandmoreSubcat from "./Home/subcategory/TeacoffeeandmoreSubcat";
import BiscuitsSubcat from "./Home/subcategory/BiscuitsSubcat";
import BathbodyandhairSubcat from "./Home/subcategory/BathbodyandhairSubcat";
import Cleaningessentials from "./Home/subcategory/Cleaningessentials";
import Beautyandgrooming from "./Home/subcategory/Beautyandgrooming";
import Homeandkitchenneeds from "./Home/subcategory/Homeandkitchenneeds";
import Pharmaandhygiene from "./Home/subcategory/Pharmaandhygiene";
import CategoriesAll from "./Home/CategoriesAll";
import useEnhancedEffect from "@mui/material/utils/useEnhancedEffect";
import { Baseurl } from "../config/BaseUrl";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { read, utils } from "xlsx";
import { productPost } from "../redux/category/CategorySlice";
import OrganicVegetable from "./Home/subcategory/OrganicVegetable";
import OrganicFruits from "./Home/subcategory/OrganicFruits";
import RecentOrders from "./Home/subcategory/RecentOrders";
import SubscriptionProduct from "./Home/SubscriptionProduct";

const Home = () => {
  const { showMassage, massageText } = useSelector(
    (state) => state.Athentication
  );
  const [visible, setVisible] = useState(false);

  const dispatch = useDispatch();

  //bulk upload
  const [data, setData] = useState("");
  const [uploadedProductName, setUploadedProductName] = useState("");

  const handlefile = async (e) => {
    const file = e.target.files[0];
    const f = await file.arrayBuffer();
    const wb = read(f);
    const worksheetname = wb.SheetNames[0];
    const worksheet = wb.Sheets[worksheetname];
    const datas = utils.sheet_to_json(worksheet);

    setData(datas);
  };

  const handlesubmit = async (e) => {
    e.preventDefault();
    let numbers = 0;
    let errordata = [];
    const fdata = data;
    for (let i in fdata) {
      let myrow = fdata[i];
      numbers = i;

      const formData = {
        ItemName: myrow.ItemName,
        ProductId: myrow.ProductId,
        Url: myrow.Url,
        superCategoryId: "",
        superCategory: "",
        superCategoryUrl: "",
        Category: "",
        CatId: "",
        categoryUrl: "",
        SubCat: "",
        SubCatId: "",
        SubCategoryUrl: "",
        Brand: "",
        BrandId: "",
        BrandUrl: "",
        KeyWords: myrow.KeyWords,
        seriesImages: [],
        Description: myrow.Description,
        Title: myrow.Title,
        About: "",
        Ingredient: "",
        ProductInfo: "",
        Type: myrow.Type,
        Rating: myrow.Rating,
        Options: myrow.Options,
        Recommends: myrow.Recommends,
        HotProducts: myrow.HotProducts,
        Trending: myrow.Trending,
        Offers: myrow.Offers,
        Multi: myrow.Multi,
        Single: myrow.Single,
        MainBannerOffer: myrow.MainBannerOffer,
        BestOffer: myrow.BestOffer,
        TodayFeature: myrow.TodayFeature,
        InFocus: myrow.InFocus,
        FeaturesInWeek: myrow.FeaturesInWeek,
        // OutOfStock: myrow.InFocus,
        Cashback: myrow.Cashback,
        Pcb1: myrow.Pcb1,
        Pcb2: myrow.Pcb2,
        Pcb3: myrow.Pcb3,
        PackSizes: [
          {
            PackSize: "",
            ImgUrlMbl: "",
            ImgUrlMblIcon: "",
            ImgUrlDesk: "",
            ImgUrlDeskIcon: "",
            CostPrc: "",
            GstCost: "",
            SellingPrice: "",
            GstSellPrc: "",
            Mrp: "",
            OutOfStock: "",
            Prime: "",
            StockAutoUpdate: "",
            Discount: "",
            CartQuantity: "",
            maximumQuantity: "",
          },
        ],
      };
      const uploadProducts = await dispatch(productPost(formData));

      if (uploadProducts.payload.success) {
        setUploadedProductName(
          `${numbers}) ${myrow.ItemName}  upload successfull`
        );
      } else {
      }
    }
  };

  return (
    <>
      {/* <div>
        <Row className="g-2  m-2">
          <Col md>
            <Form.Label htmlFor="disabledTextInput"></Form.Label>
            <Form.Control
              type="file"
              placeholder="enter name....."
              onChange={(e) => handlefile(e)}
            />
          </Col>
          <Col md>
            <button
              className="btn btn-primary me-2 mt-4 ml-5 "
              onClick={(e) => handlesubmit(e)}
            >
              Grocery Bulk Upload
            </button>
          </Col>
        </Row>
      </div> */}
      <HomeSlider />
      {/* <OrganicVegetable /> */}
      {/* <OrganicFruits /> */}
      <SubscriptionProduct />
      <RecentOrders />
      <OrganicVegetable />
      <OrganicFruits />
      <TrendingProducts />
      <CategoriesAll />
      <HotProducts />
      <DryFruifsSubcat />
      {/* <RiceDalSubcat /> */}
      {/* <Dairyandbread /> */}
      <InstantfoodSubcat />
      <ChocolatesdessertsSubcat />
      <MunchiesSubcat />

      {/* <ColddrinksandjuicesSubcat /> */}
      {/* <CerealsandbreakfastSubcat /> */}
      {/* <SaucesandspreadsSubcat /> */}
      <OfferProducts />
      <TeacoffeeandmoreSubcat />
      <BiscuitsSubcat />
      <RecommandedProducts />
      <BathbodyandhairSubcat />
      {/* <Cleaningessentials /> */}

      {/* <Beautyandgrooming /> */}
      <Homeandkitchenneeds />
      {/* <Pharmaandhygiene /> */}
      <div style={{ marginBottom: "69px" }}></div>
    </>
  );
};

export default Home;
