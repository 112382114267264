import React from "react";
import OrderHeader from "./OrderSuccess/OrderHeader";
import OrderSuccesProducts from "./OrderSuccess/OrderSuccesProducts";
import OrderSuccesCheckOut from "./OrderSuccess/OrderSuccesCheckOut";

const OrderSuccess = () => {
  return (
    <>
      <OrderHeader />

      {/* <section className="cart-section section-b-space">
        <div className="container-fluid-lg">
          <div className="row g-sm-4 g-3">
            <OrderSuccesProducts />
            <OrderSuccesCheckOut />
          </div>
        </div>
      </section> */}
    </>
  );
};

export default OrderSuccess;
