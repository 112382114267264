import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { addtoCart, decrementCart } from "../../redux/cart/CartSlice";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Button } from "@mui/material";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { IoIosArrowDown } from "react-icons/io";

const HTBProductSection = (props) => {
  const { ProductTotal, CartItems, Cartloading, ProductLoading } = useSelector(
    (state) => state.Cart
  );

  const dispatch = useDispatch();

  const [currentSubCatProducts, setCurrentSubCatProducts] = useState([]);
  const [fetchloading, setFetchloading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [curritemIndex, setcurritemIndex] = useState(0);
  const [currentProduct, setCurrentProduct] = useState("");

  const optionsProductset = (product, itemIndex) => {
    setCurrentProduct(product);
    setcurritemIndex(itemIndex);
    setVisible(!visible);
  };

  useEffect(() => {
    const prod = props.curShowProducts;
    setCurrentSubCatProducts(prod);
  }, [props.curShowProducts]);

  const addtocartPress = async (e, subcatProduct, itemIndex, packIndex) => {
    try {
      let products = [...currentSubCatProducts];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity + 1;
      setCurrentSubCatProducts([...products]);
    } catch (error) {}

    const formData = {
      ProductId: subcatProduct._id,
      ProductName: subcatProduct.ItemName,
      CatName: subcatProduct.Category,
      CatId: subcatProduct.CatId,
      SubCat: subcatProduct.SubCat,
      SubCatId: subcatProduct.SubCatId,
      Brand: subcatProduct.Brand,
      ItemName: subcatProduct.ItemName,
      PackSize: subcatProduct.PackSizes[packIndex].PackSize,
      Description: subcatProduct.Description,
      ImgUrl: subcatProduct.PackSizes[packIndex].ImgUrlMbl,
      Price: subcatProduct.PackSizes[packIndex].SellingPrice,
      Qty: 1,
      TotalAmount: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      Mrp: subcatProduct.PackSizes[packIndex].Mrp,
      TotalPrice: subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      TotalMrp: subcatProduct.PackSizes[packIndex].Mrp * 1,
      Discount:
        subcatProduct.PackSizes[packIndex].Mrp * 1 -
        subcatProduct.PackSizes[packIndex].SellingPrice * 1,
      Cashback: subcatProduct.Cashback,
      SellingPrice: subcatProduct.PackSizes[packIndex].SellingPrice,
      GstSellPrc: subcatProduct.PackSizes[packIndex].GstSellPrc,
      CostPrc: subcatProduct.PackSizes[packIndex].CostPrc,
      GstCost: subcatProduct.PackSizes[packIndex].GstCost,
      packsizeId: subcatProduct.PackSizes[packIndex]._id,
      maximumQuantity: subcatProduct.PackSizes[packIndex].maximumQuantity,
    };
    dispatch(addtoCart(formData));
  };

  const decrementtocartPress = async (e, hotPro, itemIndex, packIndex) => {
    try {
      let products = [...currentSubCatProducts];
      products[itemIndex].cartPackquentity[packIndex].CartQuantity =
        products[itemIndex].cartPackquentity[packIndex].CartQuantity - 1;

      setCurrentSubCatProducts([...products]);
    } catch (error) {}

    const formData = {
      ItemName: hotPro.ItemName,
      Category: hotPro.Category,
      CatId: hotPro.CatId,
      SubCat: hotPro.SubCat,
      SubCatId: hotPro.SubCatId,
      Brand: hotPro.Brand,
      PackSize: hotPro.PackSizes[packIndex].PackSize,
      SellingPrice: hotPro.PackSizes[packIndex].SellingPrice,
      GstSellPrc: hotPro.PackSizes[packIndex].GstSellPrc,
      Mrp: hotPro.PackSizes[packIndex].Mrp,
      CostPrc: hotPro.PackSizes[packIndex].CostPrc,
      totalMrp: hotPro.PackSizes[packIndex].Mrp * 1,
      totalPrice: hotPro.PackSizes[packIndex].SellingPrice * 1,
      totalAmount: hotPro.PackSizes[packIndex].SellingPrice * 1,
      GstCost: hotPro.PackSizes[packIndex].GstCost,
      Discount:
        hotPro.PackSizes[packIndex].Mrp * 1 -
        hotPro.PackSizes[packIndex].SellingPrice * 1,
      CartQuantity: 1,
      ImgUrlMbl: hotPro.PackSizes[packIndex].ImgUrlDesk,
      packsizeId: hotPro.PackSizes[packIndex]._id,
      productId: hotPro._id,
      maximumQuantity: hotPro.maximumQuantity,
    };
    dispatch(decrementCart(formData));
  };

  return (
    <>
      <div className="show-button">
        <div className="filter-button-group mt-0">
          <div className="filter-button d-inline-block d-lg-none">
            <a>
              <i className="fa-solid fa-filter" /> Filter Menu
            </a>
          </div>
        </div>
        {/* <div id="filter" className="top-filter-menu">
          <div className="category-dropdown">
            <h5 className="text-content">Sort By :</h5>
            <div className="dropdown">
              <button
                className="dropdown-toggle"
                type="button"
                id="dropdownMenuButton1"
                data-bs-toggle="dropdown"
              >
                <span>Most Popular</span>
                <i className="fa-solid fa-angle-down" />
              </button>
              <ul
                className="dropdown-menu"
                aria-labelledby="dropdownMenuButton1"
              >
                <li>
                  <a className="dropdown-item" id="pop">
                    Popularity
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="low">
                    Low - High Price
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="high">
                    High - Low Price
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="rating">
                    Average Rating
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="aToz">
                    A - Z Order
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="zToa">
                    Z - A Order
                  </a>
                </li>
                <li>
                  <a className="dropdown-item" id="off">
                    % Off - Hight To Low
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div> */}
      </div>
      <div
        id="productsection"
        className="row g-sm-4  row-cols-xxl-6 row-cols-xl-6 row-cols-lg-2 row-cols-md-3 row-cols-2 product-list-section"
        style={{ background: "#f8f9fa" }}
      >
        {currentSubCatProducts &&
          currentSubCatProducts.map((product, itemIndex) => (
            <div
              className="product-box-3 h-100 wow fadeInUp p-2 "
              // style={{ background: "#fff" }}
              key={itemIndex}
            >
              {product.PackSizes.map((pack, packIndex) =>
                pack.Pri === true ? (
                  <>
                    <div className="product-header">
                      <div className="product-image">
                        <Link to={`/product-info/${product.Url}`}>
                          <a>
                            <img
                              src={pack.ImgUrlDesk}
                              className="img-fluid blur-up lazyload"
                              alt
                              style={{ mixBlendMode: "multiply" }}
                            />
                            <span id="lorel">{pack.Discount}% off</span>
                            <span id="lorel1">{pack.Discount}% off</span>
                          </a>
                        </Link>
                      </div>
                    </div>
                    <div className="product-footer">
                      <div className="product-detail">
                        <span className="span-name">{product.Brand}</span>
                        <Link to={`/product-info/${product.Url}`}>
                          <a>
                            <h5 className="name">{product.ItemName}</h5>
                          </a>
                        </Link>
                        {/* <h6 className="unit"> {pack.PackSize}</h6> */}
                        <h5 className="price">
                          <span
                            className=""
                            style={{
                              color: "#000",
                              fontFamily: "Poppins",
                            }}
                          >
                            ₹{pack.SellingPrice}
                          </span>
                          {pack.Mrp === pack.SellingPrice ? (
                            <> </>
                          ) : (
                            <>
                              <del>₹{pack.Mrp}</del>
                            </>
                          )}
                        </h5>
                        {product.Multi === true ? (
                          <>
                            <div
                              style={{ display: "flex", gap: "0.5rem" }}
                              onClick={() =>
                                optionsProductset(product, itemIndex)
                              }
                            >
                              <h6 className="unit mt-1">{pack.PackSize}</h6>
                              <span>
                                <IoIosArrowDown />
                              </span>
                            </div>
                          </>
                        ) : (
                          <>
                            <h6 className="unit mt-1">{pack.PackSize}</h6>
                          </>
                        )}

                        <div className="add-to-cart-box bg-white mt-2">
                          {pack.OutOfStack === true ? (
                            <>
                              {" "}
                              <button className="btn btn-add-cart addcart-button">
                                Out of stock
                              </button>
                            </>
                          ) : (
                            <>
                              {currentSubCatProducts[itemIndex]
                                .cartPackquentity[packIndex].CartQuantity >
                              0 ? (
                                <>
                                  <div className="cart_qty qty-box open">
                                    <div
                                      className="input-group bg-white"
                                      // style={{ background: "#f8f9fa" }}
                                    >
                                      <button
                                        type="button"
                                        className="qty-left-minus"
                                        data-type="minus"
                                        data-field
                                        onClick={(e) =>
                                          decrementtocartPress(
                                            e,
                                            product,
                                            itemIndex,
                                            packIndex
                                          )
                                        }
                                        style={
                                          {
                                            // backgroundColor: "#eff9f8",
                                          }
                                        }
                                      >
                                        <i
                                          className="fa fa-minus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                      <input
                                        className="form-control input-number qty-input"
                                        type="text"
                                        name="quantity"
                                        defaultValue={0}
                                        value={
                                          currentSubCatProducts[itemIndex]
                                            .cartPackquentity[packIndex]
                                            .CartQuantity
                                        }
                                        contentEditable="false"
                                      />

                                      {11 >=
                                      currentSubCatProducts[itemIndex]
                                        .cartPackquentity[packIndex]
                                        .CartQuantity ? (
                                        <>
                                          <button
                                            type="button"
                                            className="qty-right-plus"
                                            data-type="plus"
                                            data-field
                                            style={{
                                              backgroundColor: "#eff9f8",
                                            }}
                                            onClick={(e) =>
                                              addtocartPress(
                                                e,
                                                product,
                                                itemIndex,
                                                packIndex
                                              )
                                            }
                                          >
                                            <i
                                              className="fa fa-plus"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </>
                                      ) : (
                                        <>
                                          <button
                                            type="button"
                                            className="qty-right-plus bg-gray"
                                            data-type="plus"
                                            data-field
                                          >
                                            <i
                                              className="fa fa-plus"
                                              aria-hidden="true"
                                            />
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <button
                                    className="btn btn-add-cart addcart-button"
                                    // style={{ background: "#f8f9fa" }}
                                    onClick={(e) =>
                                      addtocartPress(
                                        e,
                                        product,
                                        itemIndex,
                                        packIndex
                                      )
                                    }
                                  >
                                    Add
                                    <span
                                      className="add-icon bg-light-gray"
                                      style={{ background: "#fff" }}
                                    >
                                      <i className="fa-solid fa-plus" />
                                    </span>
                                  </button>
                                </>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div key={packIndex}></div>
                  </>
                )
              )}
            </div>
          ))}
      </div>
      <Modal
        size="md"
        // centered
        isOpen={visible}
        toggle={() => setVisible(!visible)}
        modalTransition={{ timeout: 500 }}
        className={
          window.innerWidth < 780
            ? "mobile-versionnnn-modal"
            : "desktop-versionnnnnn-modal"
        }
      >
        <ModalBody>
          <h3>{currentProduct.ItemName}</h3>
          <h5>{currentProduct.Brand}</h5>
          <div className="col-12 mt-3 ">
            {currentProduct &&
              currentProduct.PackSizes.map((packsize, packIndex) => (
                <div
                  className="col-12 d-flex justify-content-between my-2"
                  key={packsize._id}
                >
                  <div className="col-2">
                    <img
                      src={packsize.ImgUrlDesk}
                      className="img-fluid blur-up lazyload"
                      alt="image"
                      width={200}
                    />
                  </div>
                  <h6
                    className="col-1 d-flex justify-content-center align-items-center "
                    style={{
                      color: "#00ab66",
                      fontSize: "14px",
                      marginLeft: "2vh",
                    }}
                  >
                    {packsize.PackSize}
                  </h6>
                  <h6
                    className="col-3 d-flex justify-content-center align-items-center "
                    style={{
                      color: "#00ab66",
                      fontSize: "14px",
                      marginLeft: "2vh",
                    }}
                  >
                    ₹{packsize.SellingPrice}
                    {packsize.Mrp === packsize.SellingPrice ? (
                      <> </>
                    ) : (
                      <>
                        <span
                          style={{
                            color: "#000",
                            fontSize: "12px",
                            textDecorationLine: "line-through",
                            marginLeft: "0.5vh",
                          }}
                        >
                          ₹{packsize.Mrp}
                        </span>
                      </>
                    )}
                  </h6>
                  <div className="col-3 d-flex justify-content-end align-items-center">
                    {packsize.OutOfStack === true ? (
                      <>
                        {" "}
                        <Button
                          variant="contained"
                          style={{
                            color: "#fff",
                            background: "#00ab66",
                            // fontSize:"15px"
                          }}
                          className="mx-1"
                        >
                          out of stock
                        </Button>
                      </>
                    ) : (
                      <>
                        {currentProduct.cartPackquentity[packIndex]
                          .CartQuantity > 0 ? (
                          <>
                            <div className="addtocart_btn">
                              <div className="qty-box cart_qty open">
                                <div className="input-group">
                                  <button
                                    type="button"
                                    className="btn qty-left-minus"
                                    data-type="minus"
                                    data-field
                                    onClick={(e) =>
                                      decrementtocartPress(
                                        e,
                                        currentProduct,
                                        curritemIndex,
                                        packIndex
                                      )
                                    }
                                  >
                                    <i
                                      className="fa fa-minus"
                                      aria-hidden="true"
                                    />
                                  </button>

                                  <input
                                    className="form-control input-number qty-input "
                                    type="text"
                                    name="quantity"
                                    defaultValue={0}
                                    value={
                                      currentProduct.cartPackquentity[packIndex]
                                        .CartQuantity
                                    }
                                    readonly="readonly"
                                  />

                                  {11 ===
                                  currentProduct.cartPackquentity[packIndex]
                                    .CartQuantity ? (
                                    <>
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn qty-left-plus"
                                        data-type="plus"
                                        data-field
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      {" "}
                                      <button
                                        type="button"
                                        className="btn qty-left-plus"
                                        data-type="plus"
                                        data-field
                                        onClick={(e) =>
                                          addtocartPress(
                                            e,
                                            currentProduct,
                                            curritemIndex,
                                            packIndex
                                          )
                                        }
                                      >
                                        <i
                                          className="fa fa-plus"
                                          aria-hidden="true"
                                        />
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <Button
                              variant="contained"
                              style={{
                                color: "#fff",
                                background: "#00ab66",
                              }}
                              className="mx-1"
                              onClick={(e) =>
                                addtocartPress(
                                  e,
                                  currentProduct,
                                  curritemIndex,
                                  packIndex
                                )
                              }
                            >
                              Add
                              <span className="mx-1">
                                {/* <i className="fa-solid fa-plus" /> */}
                              </span>
                            </Button>
                          </>
                        )}
                      </>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </ModalBody>
      </Modal>
      {/* <nav className="custome-pagination">
        <ul className="pagination justify-content-center">
          <li className="page-item disabled">
            <a
              className="page-link"
              
              tabIndex={-1}
              aria-disabled="true"
            >
              <i className="fa-solid fa-angles-left" />
            </a>
          </li>
          <li className="page-item active">
            <a className="page-link" >
              1
            </a>
          </li>
          <li className="page-item" aria-current="page">
            <a className="page-link" >
              2
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" >
              3
            </a>
          </li>
          <li className="page-item">
            <a className="page-link" >
              <i className="fa-solid fa-angles-right" />
            </a>
          </li>
        </ul>
      </nav> */}
    </>
  );
};

export default HTBProductSection;
