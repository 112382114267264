import React from "react";
import { MoneyCollectOutlined } from "@ant-design/icons";
import { Timeline } from "antd";
import { IoIosShareAlt } from "react-icons/io";
import { BsBagCheck } from "react-icons/bs";
import { MdOutlineCurrencyRupee } from "react-icons/md";

const ManageRefferals = () => {
  return (
    <>
      <div
        className="refferal_container"
        style={{
          background: "linear-gradient(to bottom, #00ab66, #b3ffe0,#fff)",
          height: "80vh",
        }}
      >
        <div className="refferral_head">
          <div className="refferral_headtext">
            25% off for you, Pass for them @ ₹1!
          </div>
          <div className="refferral_main">
            <div className="refferral_middle">
              <div className="refferral_body">
                <div className="refferral_body_text">How it works</div>

                <div className="refferral_middle_body">
                  <Timeline
                    items={[
                      {
                        dot: <IoIosShareAlt className="timeline-clock-icon" />,
                        color: "#808080",
                        children: (
                          <>
                            Share the referral link <b>with your friend</b>
                          </>
                        ),
                      },
                      {
                        dot: <BsBagCheck className="timeline-clock-icon" />,
                        color: "#808080",
                        children: (
                          <>
                            After your friend places their first order, you{" "}
                            <b>get 25% off</b> up to ₹200 on your next order
                          </>
                        ),
                      },
                      {
                        dot: (
                          <MdOutlineCurrencyRupee className="timeline-clock-icon" />
                        ),
                        color: "#808080",
                        children: (
                          <>
                            Upon 10 successful referrals, <b>you earn ₹2000</b>
                          </>
                        ),
                      },
                    ]}
                  />
                </div>

                <div className="refferral_body_lastcon">
                  <div className="refferral_body_wpbtn">
                    <div className="refferral_body_wpimgcon">
                      <img
                        src="/assets/images/whatsapp.png"
                        className="refferral_body_wpimg"
                      />
                      <div className="refferral_body_wpimgtext">
                        Invite via Whatsapp
                      </div>
                    </div>
                  </div>
                  <div className="refferral_body_wpbtn">
                    <div className="refferral_body_wpimgcon">
                      <img
                        src="/assets/images/share.png"
                        className="refferral_body_wpimg"
                      />
                      <div className="refferral_body_wpimgtext">
                        Share Invite Link
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              style={{
                margin: "15px 0px",
                border: "1px solid rgb(148 177 200 / 19%)",
              }}
            ></div>
            <div>
              <div className="your_referrals">Your Referrals</div>
              <div className="your_referrals_paragraph">
                No referrals yet. Share with friends to start saving!
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageRefferals;
