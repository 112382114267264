import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { GiHamburgerMenu, GiElectric } from "react-icons/gi";
import { TfiLocationPin } from "react-icons/tfi";
import { FiSearch, FiUser } from "react-icons/fi";
import { BsTelephone, BsCart3 } from "react-icons/bs";
import { AiOutlineUser } from "react-icons/ai";
import { FaRegHeart, FaUserCircle } from "react-icons/fa";
import { IoHomeOutline } from "react-icons/io5";
import { FaRegUserCircle } from "react-icons/fa";
import { GrFormClose } from "react-icons/gr";
import { FaLocationDot } from "react-icons/fa6";
import { IoIosNotifications } from "react-icons/io";
import { FaFirstOrderAlt } from "react-icons/fa";
import { useSelector, useDispatch } from "react-redux";
import { BiCategoryAlt } from "react-icons/bi";
import { BsArrowRepeat } from "react-icons/bs";
import { MdLocationOn } from "react-icons/md";
import { LuShoppingBag } from "react-icons/lu";
import { FaFacebook } from "react-icons/fa";
import { FaInstagramSquare } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import { FaTwitter } from "react-icons/fa";
import { IoIosFlash } from "react-icons/io";
import {
  getCartTotal,
  removefromCart,
  setSearchProducts,
} from "../../redux/cart/CartSlice";
import {
  pageStatusChange,
  set_nearest_store,
  setcurrent_deliveryTime,
} from "../../redux/athentication/Athentication";
import { Baseurl } from "../../config/BaseUrl";
import axios from "axios";
import { authActions } from "../../redux/athentication/Athentication";
import { clearOrder } from "../../redux/order/OrderSlice";

const Header = () => {
  const { CartItems, cartTotalAmount, ProductTotal, SelectedCity } =
    useSelector((state) => state.Cart);
  const { subCategoryTotal } = useSelector((state) => state.SubCategory);
  const { categoryTotal } = useSelector((state) => state.Category);
  // const { groceresBrand } = useSelector((state) => state.Brand);

  const {
    isAuth,
    name,
    loginData,
    showMassage,
    massageText,
    city_stores,
    current_deliveryTime,
  } = useSelector((state) => state.Athentication);

  const [search, setSerch] = useState("");
  const [subCatSerchList, setSubCatSerchList] = useState("");
  const [catSerchList, setCatSerchList] = useState([]);
  const [prodSerchList, setProdSerchList] = useState([]);
  const [brandprodSerchList, setBrandProdSerchList] = useState("");
  const [showResults, setShowResults] = useState(false);

  const [fetchAllProducts, setFetchAllProducts] = useState([]);
  const [location, setLocation] = useState({ latitude: null, longitude: null });
  const [address, setAddress] = useState("");
  const [distance_partner, setdistance_partner] = useState("");
  const [currentcity, setcurrentcity] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();

  let travel_time = 20;

  const placeholderArray = [
    "I'm searching for Organic Vegetable..",
    "I'm searching for Organic Fruits..",
    "I'm searching for Dry Fruits...",
    "I'm searching for Chocolates desserts...",
  ];

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % placeholderArray.length);
    }, 2000);

    return () => clearInterval(intervalId);
  }, [placeholderArray.length]);

  // current location google api
  const fetchAddress = async (latitude, longitude) => {
    try {
      const apiKey = "AIzaSyD9On5PRHixcY4UO4T-0DowNTcDyBhMrlI";
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKey}`
      );

      if (response.data.status !== "OK") {
        throw new Error("Error fetching data from Google Maps API");
      }
      const fullAddress = response.data.results[0]?.formatted_address;
      const fullAddress2 = response.data.results[0]?.formatted_address;
      const addressComponents =
        response.data.results[0]?.address_components || [];

      const getAddressComponent = (type) =>
        addressComponents.find((component) => component.types.includes(type))
          ?.long_name || "";

      const address = {
        rodeno: getAddressComponent("route")
          ? `${getAddressComponent("route") + ", "}`
          : "",
        sublocality2: getAddressComponent("sublocality_level_2")
          ? `${getAddressComponent("sublocality_level_2") + ", "}`
          : "",
        sublocality1: getAddressComponent("sublocality_level_1")
          ? `${getAddressComponent("sublocality_level_1") + ", "}`
          : "",
        pinCode: getAddressComponent("postal_code"),
        city: getAddressComponent("locality"),
        state: getAddressComponent("administrative_area_level_1"),
        country: getAddressComponent("country"),
        locality:
          getAddressComponent("sublocality") ||
          getAddressComponent("sublocality_level_1"),
      };
      const add_stn = `${address.rodeno}${address.sublocality2}${address.sublocality1}${address.locality}, ${address.city}`;
      setAddress(add_stn);
      setcurrentcity(address.city);
      return address;
    } catch (error) {
      return null;
    }
  };

  // current location google api

  useEffect(() => {
    const getLocation = () => {
      try {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              // console.log(position.coords, "position.coords");
              const { latitude, longitude } = position.coords;
              const latlog = {
                latitude: latitude,
                longitude: longitude,
              };
              setLocation(latlog);
              fetchAddress(latitude, longitude);
            },
            (error) => {
              console.error(
                "Error Code = " + error.code + " - " + error.message
              );
            }
          );
        }
      } catch (error) {}
    };
    getLocation();
  }, []);

  useEffect(() => {
    function calculateDistance(lat1, lon1, lat2, lon2) {
      const R = 6371; // Radius of the Earth in kilometers
      const dLat = ((lat2 - lat1) * Math.PI) / 180;
      const dLon = ((lon2 - lon1) * Math.PI) / 180;
      const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat1 * Math.PI) / 180) *
          Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) *
          Math.sin(dLon / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const distance = R * c;
      console.log(distance, "distance");

      const timeInHours = distance / 40;
      console.log(timeInHours, "timeInHours");

      const timeInMinutes = timeInHours * 60;
      console.log(timeInMinutes, "timeInMinutes");

      const time = Math.ceil(timeInMinutes);
      const fid = {
        time: Number(time) > 10 ? 20 : time,
        distance: distance,
      };
      return fid;
    }
    const myLat = location?.latitude; // My latitude
    const myLng = location?.longitude; // My longitude
    const filteredPartner = city_stores
      ?.map((storesd) => {
        const distance = calculateDistance(
          myLat,
          myLng,
          storesd.address.latitude,
          storesd.address.longitude
        );

        return {
          ...storesd,
          distance: distance.distance.toFixed(2),
          time: distance.time + 6,
        };
      })
      .sort((a, b) => (a.distance > b.distance ? -1 : 1));
    if (filteredPartner.length > 0) {
      const shortestDistanceObject = filteredPartner.reduce(
        (shortest, current) => {
          return parseFloat(current.distance) < parseFloat(shortest.distance)
            ? current
            : shortest;
        },
        filteredPartner[0]
      );
      travel_time = shortestDistanceObject.time;
      setdistance_partner(shortestDistanceObject);
      dispatch(setcurrent_deliveryTime(shortestDistanceObject));
      dispatch(set_nearest_store(shortestDistanceObject));
    }
  }, [city_stores, location]);

  useEffect(() => {
    const checkPermissionAndFetch = async () => {
      const permissionStatus = await navigator.permissions.query({
        name: "geolocation",
      });
      if (
        permissionStatus.state === "denied" ||
        permissionStatus.state === "prompt"
      ) {
        const fetchLocation = async () => {
          try {
            const response = await fetch("https://ipapi.co/json/"); // Use any geolocation API
            const data = await response.json();
            // setLocation({
            //   city: data.city,
            //   region: data.region,
            //   country: data.country_name,
            // });
            setcurrentcity(data.city);
            const locatioFrm = {
              latitude: data.latitude,
              longitude: data.longitude,
            };
            setLocation(locatioFrm);
            const add_stn = `Vengal Rao Nagar,SR Nagar,Hyderabad`;
            setAddress(add_stn);
          } catch (error) {
            console.error("Error fetching location data:", error);
          }
        };

        fetchLocation();
      }
    };

    if (address === "") {
      checkPermissionAndFetch();
    }
  }, [address]);

  useEffect(() => {
    const clickoutside = (e) => {
      if (showResults && ref.current && !ref.current.contains(e.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener("click", clickoutside);
    return () => {
      document.addEventListener("click", clickoutside);
    };
  }, [showResults]);

  useEffect(() => {
    dispatch(getCartTotal());
    dispatch(pageStatusChange(0));
  }, [CartItems, dispatch]);

  const removefromcarts = (cartItem) => {
    dispatch(removefromCart(cartItem));
  };

  const searchchange = (e) => {
    const result = e.target.value;
    setSerch(result);

    if (result.length >= 2) {
      const filtersubcatbyserch = subCategoryTotal
        .filter((subcat) =>
          subcat.name.toLowerCase().match(result.toLowerCase())
        )
        .slice(0, 2); //Only the first 2 matching results are selected

      const filtercatdbyserch = categoryTotal
        .filter((cat) => cat.name.toLowerCase().match(result.toLowerCase()))
        .slice(0, 2);
      const filterProductbyserch = ProductTotal.filter((product) =>
        product.ItemName.toLowerCase().match(result.toLowerCase())
      ).slice(0, 2);
      setSubCatSerchList(filtersubcatbyserch);
      setCatSerchList(filtercatdbyserch);
      setProdSerchList(filterProductbyserch);
      // setBrandProdSerchList(filterBrandbyserch);
      setShowResults(true);
    }
  };

  const searchsubmit = (e) => {
    e.preventDefault();
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };
  const handleSelection = (selectedItem) => {
    setSerch(selectedItem);
    setShowResults(false);
  };

  const serchclick = () => {
    if (search !== "") {
      navigate(`/product-search/${search}`);
      setShowResults(false);
    }
  };

  return (
    <>
      <header className="pb-md-3 hedcontainermain">
        <div className="topheaderbar">
          <marquee className="scrolling-text" direction="left">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div className="martext">
                Exciting News! 🎉 We're launching our brand-new website on 18th
                October! Stay tuned for exclusive offers, updates, and a whole
                new experience.
              </div>
            </div>
          </marquee>
        </div>
        {/* =============header top start===================== */}
        <div className="top-nav top-header sticky-header hedcontainermain">
          <div className="container-fluid-lg hedcontainermain1">
            <div className="row">
              <div className="col-12">
                <div className="navbar-top">
                  <a className="web-logo nav-logo">
                    <Link to="/">
                      <img
                        // src="../assets/images/kisanlogo2.jpg"
                        src="../assets/images/kisanlogo20.png"
                        className="img-fluid blur-up lazyload"
                        alt="img"
                        style={{
                          filter: "drop-shadow(1.5px 1px 0.2px #fff)",
                        }}
                      />
                    </Link>
                  </a>

                  {/* ============middle start============== */}
                  <div className="middle-box dextop">
                    {isAuth === true ? (
                      <>
                        <Link to="/account">
                          {currentcity === SelectedCity ? (
                            <>
                              <div className="location_box_desk">
                                <h4 className="address_texth">
                                  Delivery in{" "}
                                  <span>
                                    {travel_time > 20 ? 20 : travel_time} Mins
                                  </span>
                                </h4>
                                <p className="header_address_text">{address}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="location_box_desk">
                                <p className="header_address_text">
                                  we are not delivering your address
                                </p>
                              </div>
                            </>
                          )}
                        </Link>
                      </>
                    ) : (
                      <>
                        <Link to="/login">
                          {currentcity === SelectedCity ? (
                            <>
                              <div className="location_box_desk">
                                <h4 className="address_texth">
                                  Delivery in{" "}
                                  <span>
                                    {travel_time > 20 ? 20 : travel_time} Mins
                                  </span>
                                </h4>
                                <p className="header_address_text">{address}</p>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="location_box_desk">
                                <p className="header_address_text">
                                  we are not delivering your address
                                </p>
                              </div>
                            </>
                          )}
                        </Link>
                      </>
                    )}

                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              backgroundColor: "#01cb88",
                              color: "#fff",
                              borderColor: "#01cb88",
                              borderWidth: ".3px",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            onChange={searchchange}
                          />
                          {/* {typing ? (
                            <></>
                          ) : (
                            <div
                              className={`placeholder-text ${animationClass}`}
                              style={{
                                position: "absolute",
                                top: 8,
                                left: 105,
                              }}
                            >
                              {`'${currentPlaceholder}'`}
                            </div>
                          )} */}
                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                            style={{
                              backgroundColor: "#01cb88",
                              borderColor: "#01cb88",
                              boxShadow:
                                "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                            }}
                          >
                            <FiSearch style={{ fontSize: "20px" }} />
                          </button>
                        </div>
                      </form>

                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist">
                            {/* <ul>
                              {subCatSerchList &&
                                subCatSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6>in {data.category} Category</h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul> */}
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00ab66" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  {/* ============middle end============== */}

                  <div className="rightside-box">
                    <div className="search-full">
                      <div className="input-group">
                        <span className="input-group-text">
                          <FiSearch />
                        </span>
                        <input
                          type="text"
                          className="form-control search-type"
                          placeholder="Search here.."
                        />
                        <span className="input-group-text close-search">
                          <i data-feather="x" className="font-light" />
                        </span>
                      </div>
                    </div>
                    <ul className="right-side-menu">
                      <li className="right-side">
                        <div className="delivery-login-box">
                          <div className="delivery-icon">
                            <div className="search-box">
                              <i data-feather="search" />
                            </div>
                          </div>
                        </div>
                      </li>
                      {/* <li className="right-side">
                        <a className="delivery-login-box">
                          <Link>
                            <div
                              className="delivery-icon"
                              style={{ color: "#00b300" }}
                            >
                              <BsTelephone />
                            </div>
                          </Link>
                          <div className="delivery-detail">
                            <h6>24/7 Delivery</h6>
                            <h5>+91 9014 775 214</h5>
                          </div>
                        </a>
                      </li> */}
                      {/* <li className="right-side">
                        <Link to="wishlist">
                          <a className="btn p-0 position-relative header-wishlist">
                            <FaRegHeart />
                          </a>
                        </Link>
                      </li> */}
                      <li className="right-side">
                        <div className="onhover-dropdown header-badge">
                          <Link to="/cart">
                            {CartItems.length === 0 ? (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn p-0 position-relative header-wishlist"
                              >
                                <BsCart3
                                  style={{
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                />
                                <span
                                  className="position-absolute top-0 start-100 translate-middle badge"
                                  style={{
                                    backgroundColor: "#f39636",
                                    borderRadius: "50px",
                                  }}
                                >
                                  {CartItems.length}
                                </span>
                              </button>
                            )}
                          </Link>
                          {/* {CartItems.length === 0 ? (
                            <></>
                          ) : (
                            <div className="onhover-div">
                              <ul className="cart-list">
                                {CartItems.map((data, index) => (
                                  <li
                                    className="product-box-contain"
                                    key={index}
                                  >
                                    <div className="drop-cart">
                                      <a className="drop-image">
                                        <img
                                          src={data.ImgUrl}
                                          className="blur-up lazyload"
                                          alt="img"
                                        />
                                      </a>
                                      <div className="drop-contain">
                                        <a>
                                          <h5>{data.ItemName}</h5>
                                        </a>
                                        <h6>
                                          <span>{data.Qty} x</span> ₹
                                          {data.Price}
                                        </h6>
                                        <button
                                          className="close-button close_button"
                                          onClick={() => removefromcarts(data)}
                                        >
                                          <GrFormClose />
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                ))}
                              </ul>
                              <div className="price-box">
                                <h5>Total :</h5>
                                <h4 className="theme-color fw-bold">
                                  ₹{cartTotalAmount}
                                </h4>
                              </div>
                              <div className="button-group">
                                <Link to="/cart">
                                  <button className="btn btn-sm cart-button">
                                    View Cart
                                  </button>
                                </Link>

                                {isAuth ? (
                                  <>
                                    <Link to="/checkout">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                ) : (
                                  <>
                                    <Link to="/login">
                                      <button className="btn btn-sm cart-button theme-bg-color text-white">
                                        Checkout
                                      </button>
                                    </Link>
                                  </>
                                )}
                              </div>
                            </div>
                          )} */}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          {isAuth ? (
                            <Link to="/account">
                              <div
                                className="delivery-icon"
                                style={{ color: "#fff", fontFamily: "Poppins" }}
                              >
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />{" "}
                                {name}
                              </div>
                            </Link>
                          ) : (
                            <Link to="/login">
                              <div className="delivery-icon loginserch">
                                <FaRegUserCircle
                                  style={{
                                    fontSize: "22px",
                                    color: "#fff",
                                    fontFamily: "Poppins",
                                  }}
                                  className="mx-2"
                                />
                              </div>
                            </Link>
                          )}
                        </div>
                      </li>
                      <li className="right-side onhover-dropdown">
                        <div className="delivery-login-box">
                          <Link>
                            <div
                              className="delivery-icon"
                              style={{ color: "#fff", fontFamily: "Poppins" }}
                            >
                              <LuShoppingBag
                                style={{
                                  fontSize: "22px",
                                  color: "#fff",
                                  fontFamily: "Poppins",
                                }}
                                className="mx-2"
                              />
                            </div>
                          </Link>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>

                {showMassage === true ? (
                  <>
                    {/* <div
                      style={{
                        width: "100%",
                        height: "5vh",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        backgroundColor: "#0e947a",
                        borderRadius: "3px",
                      }}
                    >
                      <marquee scrolldelay="100">
                        <h6
                          style={{
                            fontSize: "1.1rem",
                            fontWeight: "600",
                            color: "#fff",
                          }}
                        >
                          {massageText}
                        </h6>
                      </marquee>
                    </div> */}
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "#fff",
            }}
          >
            <div style={{ width: "92%" }}>
              <div style={{ padding: "6px" }} className="locationdeskversion">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  {isAuth === false ? (
                    <>
                      <Link to="/account">
                        {currentcity === SelectedCity ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div className="mobile_loc_con">
                                <div className="location_box_desk">
                                  <h4 className="address_texth">
                                    <IoIosFlash
                                      style={{
                                        fontSize: "17px",
                                        color: "#00ab66",
                                        fontWeight: "600",
                                      }}
                                    />{" "}
                                    Delivery in{" "}
                                    <span>
                                      {travel_time > 20 ? 20 : travel_time} Mins
                                    </span>
                                  </h4>
                                  <p className="header_address_text">
                                    {address}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="location_box_desk">
                              <p className="header_address_text">
                                we are not delivering your address
                              </p>
                            </div>
                          </>
                        )}
                      </Link>
                    </>
                  ) : (
                    <>
                      <Link to="/login">
                        {currentcity === SelectedCity ? (
                          <>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <div className="mobile_loc_con">
                                <div className="location_box_desk">
                                  <h4 className="address_texth">
                                    <IoIosFlash
                                      style={{
                                        fontSize: "17px",
                                        color: "#00ab66",
                                        fontWeight: "600",
                                      }}
                                    />{" "}
                                    Delivery in{" "}
                                    <span>
                                      {travel_time > 20 ? 20 : travel_time} Mins
                                    </span>
                                  </h4>
                                  <p className="header_address_text">
                                    {address}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="location_box_desk">
                              <p className="header_address_text">
                                we are not delivering your address
                              </p>
                            </div>
                          </>
                        )}
                      </Link>
                    </>
                  )}

                  {/* <Link to="/notification">
                    <div
                      style={{
                        height: "35px",
                        width: "35px",
                        borderRadius: "20px",
                        backgroundColor: "#eff9f8",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <IoIosNotifications style={{ fontSize: "21px" }} />
                    </div>
                  </Link> */}
                </div>
                <div className="navbar-top ">
                  <div className="middle-box mobile mt-2 ">
                    {/* <div className="location-box">
                      <button
                        className="btn location-button"
                        data-bs-toggle="modal"
                        data-bs-target="#locationModal"
                      >
                        <span className="location-arrow">
                          <TfiLocationPin />
                        </span>
                        <span className="locat-name">Your Location</span>
                        <i className="fa-solid fa-angle-down" />
                      </button>
                    </div> */}
                    <div className="search-box" ref={ref}>
                      <form onSubmit={(e) => searchsubmit(e)}>
                        <div className="input-group">
                          <input
                            type="search"
                            style={{
                              borderTopLeftRadius: "7px",
                              borderBottomLeftRadius: "7px",
                            }}
                            className="form-control"
                            placeholder={placeholderArray[currentIndex]}
                            aria-label="Recipient's username"
                            aria-describedby="button-addon2"
                            value={search}
                            ref={ref}
                            onChange={searchchange}
                          />
                          <button
                            className="btn"
                            type="button"
                            id="button-addon2"
                            onClick={serchclick}
                          >
                            <FiSearch />
                          </button>
                        </div>
                      </form>
                      {showResults && prodSerchList.length > 0 && (
                        <>
                          <div className="serchsugist ">
                            <ul>
                              {prodSerchList &&
                                prodSerchList.map((data, index) => (
                                  <Link to={`/product-info/${data.Url}`}>
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() =>
                                        handleSelection(data.Category)
                                      }
                                    >
                                      {data.PackSizes.map((pack, packIndex) =>
                                        pack.Pri === true ? (
                                          <>
                                            <img
                                              src={pack.ImgUrlDesk}
                                              alt="img"
                                              width={50}
                                              key={packIndex}
                                            />
                                          </>
                                        ) : (
                                          <></>
                                        )
                                      )}

                                      <div className="mx-3">
                                        <h6>{data.ItemName}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          In Product
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {catSerchList &&
                                catSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/category-search/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.mobileImage}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6>{data.name}</h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          in {data.superCategory} Super Category
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                            <ul>
                              {brandprodSerchList &&
                                brandprodSerchList.map((data, index) => (
                                  <Link
                                    className="suglink"
                                    to={`/grocery/grocery-brand-serch/${data.slugUrl}`}
                                    key={index}
                                  >
                                    <li
                                      className="d-flex py-1 liclas"
                                      key={index}
                                      onClick={() => handleSelection(data.name)}
                                    >
                                      <img
                                        src={data.icon}
                                        alt="img"
                                        width={50}
                                      />
                                      <div className="mx-3">
                                        <h6> </h6>
                                        <h6 style={{ color: "#00b300" }}>
                                          {" "}
                                          {data.name} in Brand
                                        </h6>
                                      </div>
                                    </li>
                                  </Link>
                                ))}
                            </ul>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* =============header top end===================== */}
      </header>

      {/* ======mobile menu start====== */}
      <div className="mobile-menu d-md-none d-block mobile-cart">
        <ul>
          <li className="active">
            <Link to="/">
              <a>
                {/* <IoHomeOutline className=" icli" /> */}
                <Link to="/">
                  <img
                    src="../assets/images/kisanlogo3.jpg"
                    alt="img"
                    height="21px"
                    width="40px"
                  />
                </Link>
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "12px",
                    fontFamily: "Poppins",
                  }}
                >
                  Home
                </span>
              </a>
            </Link>
          </li>
          <li className="mobile-category">
            <Link to="/CategoryScreen">
              <a>
                <BiCategoryAlt
                  style={{ color: "#404040", fontWeight: "600" }}
                  className="iconly-Category icli js-link"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Category
                </span>
              </a>
            </Link>
          </li>
          {/* <li>
            <Link to="/">
              <a className="search-box">
                <FiSearch className="iconly-Search icli" />
                <span>Search</span>
              </a>
            </Link>
          </li> */}
          <li>
            <Link to="">
              <a className="notifi-wishlist">
                {/* <FaRegHeart
                  style={{ color: "#767676", fontWeight: "600" }}
                  className="iconly-Heart icli"
                /> */}
                {/* <FaFirstOrderAlt  /> */}
                <BsArrowRepeat
                  style={{
                    color: "#404040",
                    fontWeight: "600",
                    fontSize: "21px",
                  }}
                  className="iconly-Heart icli"
                />
                <span
                  style={{
                    color: "#767676",
                    fontWeight: "500",
                    fontSize: "11px",
                    fontFamily: "Poppins",
                  }}
                >
                  Order Again
                </span>
              </a>
            </Link>
          </li>
          <li>
            <Link to="/cart">
              <div className="onhover-dropdown header-badge">
                <a className="position-relative header-wishlist">
                  <BsCart3
                    style={{ color: "#404040", fontWeight: "600" }}
                    className="iconly-Bag-2 icli fly-cate"
                  />
                  <span
                    style={{
                      color: "#767676",
                      fontWeight: "500",
                      fontSize: "11px",
                      fontFamily: "Poppins",
                    }}
                  >
                    Cart
                  </span>
                  <span
                    className="position-absolute  translate-middle badge "
                    style={{
                      top: "5px",
                      left: "55px",
                      backgroundColor: "#f6f6f6",
                      color: "#04ab66",
                    }}
                  >
                    {CartItems.length}
                  </span>
                </a>
              </div>
            </Link>
          </li>
          <li>
            {/* <Link to="/login">
              <a className="notifi-wishlist">
                <FaRegUserCircle className="iconly-Heart icli" />
                <span>Account</span>
              </a>
            </Link> */}
            <li>
              {isAuth ? (
                <Link to="/account">
                  <a style={{ color: "#000" }} className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />
                    {/* <span style={{width:"100px"}}> {name}</span> */}
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              ) : (
                <Link to="/login">
                  <a className="notifi-wishlist">
                    <FaRegUserCircle
                      style={{ color: "#404040", fontWeight: "600" }}
                      className="iconly-Heart icli"
                    />
                    <span
                      style={{
                        color: "#767676",
                        fontWeight: "500",
                        fontSize: "11px",
                        fontFamily: "Poppins",
                      }}
                    >
                      Account
                    </span>
                  </a>
                </Link>
              )}
            </li>
          </li>
        </ul>
      </div>
      {/* ======mobile menu end====== */}
    </>
  );
};

export default Header;
